import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: -webkit-fill-available;\n  height: 100%;\n  justify-content: space-between;\n  font-weight: 400;\n  font-family: ",
        ";\n  color: ",
        ";\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    padding: 12px 3px 0 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    text-align: center;\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  font-family: ",
        ";\n  ",
        "\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    text-align: center;\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  // position: ",
        ";\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: center;\n  background: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n    flex-direction: row;\n    gap: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n    gap: 32px;\n  }\n\n  div {\n    width: 100%;\n    justify-content: center;\n    display: flex;\n\n    button {\n      max-width: 296px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  overflow: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
/* stylelint-disable value-no-vendor-prefix */ export var Container = styled.div.withConfig({
    componentId: "sc-28aba27-0"
})(_templateObject(), fonts.exbold, colors.$262626, breakpoints.tablet);
export var Title = styled.div.withConfig({
    componentId: "sc-28aba27-1"
})(_templateObject1(), pxToCssFont(30, 38), breakpoints.tablet, breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-28aba27-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, breakpoints.desktop);
export var ButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-28aba27-3"
})(_templateObject3(), function(param) {
    var isSticky = param.isSticky;
    return isSticky ? "sticky" : "static";
}, colors.$ffffff, breakpoints.tablet, function(param) {
    var isSticky = param.isSticky;
    return isSticky ? "20px" : "40px";
}, breakpoints.desktop, function(param) {
    var isSticky = param.isSticky;
    return isSticky ? "24px" : "48px";
});
export var TextContainer = styled.div.withConfig({
    componentId: "sc-28aba27-4"
})(_templateObject4(), function(param) {
    var isSticky = param.isSticky;
    return isSticky ? "auto" : "unset";
});
