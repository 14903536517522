import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
import { setTrackLink } from "@vfit/shared/data-access";
import { useEffect } from "react";
var TaggingDeviceDetails = function(param) {
    var device = param.device, taggingOptions = param.taggingOptions, taggingProduct = param.taggingProduct, selectedSku = param.selectedSku;
    var client = useQueryClient();
    var trackWithNewDatalayer = useTracking({
        event: [
            "product_view"
        ],
        disableInitialTrack: true
    }).trackWithNewDatalayer;
    setTrackLink(trackWithNewDatalayer);
    useEffect(function() {
        var ref;
        selectedSku && trackWithNewDatalayer({
            event: [
                "product_view"
            ],
            event_label: SoftManagerService(client).getPromoLabel((device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.slug) || ""),
            opts: taggingOptions,
            pageProduct: taggingProduct,
            cartProduct: undefined,
            visitorTrackingOpts: SoftManagerService(client).getUser()
        });
    }, [
        selectedSku
    ]);
    return null;
};
export default TaggingDeviceDetails;
