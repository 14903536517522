import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 20px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 54px;\n  }\n\n  display: flex;\n  flex-direction: column;\n  height: inherit;\n  justify-content: space-between;\n  text-align: center;\n  font-weight: 400;\n  font-family: ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  font-weight: 400;\n  color: ",
        ";\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    margin-top: 60px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-d99f0432-0"
})(_templateObject(), breakpoints.tablet, breakpoints.tablet, fonts.exbold, colors.$262626);
export var Title = styled.div.withConfig({
    componentId: "sc-d99f0432-1"
})(_templateObject1(), colors.$262626, fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
