import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 0 16px;\n  font-weight: 400;\n  ",
        "\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 60px 0 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  ",
        "\n  font-family: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    max-height: inherit;\n    margin: 0 auto;\n    width: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n    -ms-overflow-style: none; /* for Internet Explorer, Edge */\n    scrollbar-width: 0;\n\n    ::-webkit-scrollbar {\n      width: 0; /* for Chrome, Safari, and Opera */\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    max-height: inherit;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var Title = styled.div.withConfig({
    componentId: "sc-56c75ee7-0"
})(_templateObject(), pxToCssFont(30, 38), fonts.exbold, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-56c75ee7-1"
})(_templateObject1(), pxToCssFont(18, 24), fonts.regular);
export var Wrapper = styled.div.withConfig({
    componentId: "sc-56c75ee7-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
