import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n\n  .button-slide-container {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-top: 32px;\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  justify-content: center;\n\n  .button-slide-container {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ModalWrap = styled.div.withConfig({
    componentId: "sc-a5459586-0"
})(_templateObject());
export var Paragraph = styled.p.withConfig({
    componentId: "sc-a5459586-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-a5459586-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalTitle = styled(Title).withConfig({
    componentId: "sc-a5459586-3"
})(_templateObject3(), breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled(Paragraph).withConfig({
    componentId: "sc-a5459586-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(18, 24));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-a5459586-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
