import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  margin-left: 24px;\n  margin-right: 24px;\n  height: 90px;\n  margin-bottom: 16px;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  @media (min-width: ",
        ") {\n    width: 100vh;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 48px 23px 31px;\n  width: 300px;\n\n  @media (min-width: ",
        ") {\n    width: 420px;\n    margin: 0 23px 31px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 624px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var ProductSolutionTextLoader = styled.div.withConfig({
    componentId: "sc-c0273b05-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet);
export var ProductSolutionContainerText = styled.div.withConfig({
    componentId: "sc-c0273b05-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.bigDesktop);
