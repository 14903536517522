var DEFAULT_SIZE = {
    default: {
        width: 405
    },
    tablet: {
        width: 327
    },
    mobile: {
        width: 327
    }
};
/**
 * Return the size of slide
 * @param slideIndex
 * @param isMobile
 * @param isTablet
 */ var getSlideSize = function(slideIndex, isMobile, isTablet) {
    if (!isMobile && !isTablet) return DEFAULT_SIZE.default;
    return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.tablet || DEFAULT_SIZE.default;
};
export { getSlideSize, DEFAULT_SIZE };
