import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  width: 287px;\n\n  @media (min-width: ",
        ") {\n    width: 366px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 406px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 0 16px;\n  font-family: ",
        ";\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 60px 0 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  margin-bottom: 32px;\n  text-align: center;\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n\n  @media (min-width: ",
        ") {\n    gap: ",
        ";\n  }\n\n  div {\n    margin-top: 0;\n  }\n\n  p {\n    position: relative;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  margin-top: ",
        ";\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-cc74b2ac-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-cc74b2ac-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-cc74b2ac-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var Div = styled.div.withConfig({
    componentId: "sc-cc74b2ac-3"
})(_templateObject3(), pxToRem(16), breakpoints.tablet, pxToRem(20));
export var Footer = styled.div.withConfig({
    componentId: "sc-cc74b2ac-4"
})(_templateObject4(), fonts.regular, pxToCssFont(14, 18), pxToRem(16), pxToRem(32), breakpoints.tablet, pxToRem(20));
export var skeletonShape = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 90,
            marginBottom: 32
        },
        {
            heights: 60,
            repeat: 4,
            gap: 15
        }, 
    ]
};
