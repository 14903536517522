import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  min-height: 344px;\n\n  @media (min-width: ",
        ") {\n    // min-height: 800px;\n    min-height: 680px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: inherit;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 0 0 20px 20px;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: calc(100vh - 250px);\n  max-height: 80vh;\n  border-radius: 0 0 30px 30px;\n  position: relative;\n\n  div {\n    position: absolute;\n    width: 100%;\n  }\n\n  div::after {\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    content: '';\n    background-image: linear-gradient(90deg, rgba(62, 62, 62, 6%) 17.03%, rgba(0, 0, 0, 0%) 83.87%);\n\n    @media (min-width: ",
        ") {\n      border-radius: 0 0 30px 30px;\n    }\n\n    @media (min-width: ",
        ") {\n      border-radius: 0 0 30px 30px;\n    }\n  }\n\n  video {\n    object-fit: cover;\n    width: 100%;\n    height: calc(100vh - 250px);\n\n    @media (min-width: ",
        ") {\n      object-fit: cover;\n      border-radius: 0 0 30px 30px;\n    }\n\n    @media (min-width: ",
        ") {\n      object-fit: cover;\n      border-radius: 0 0 30px 30px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n  padding-left: 30px;\n  margin-bottom: 28px;\n  display: flex;\n  align-items: center;\n\n  .back {\n    width: inherit;\n    display: flex;\n    cursor: pointer;\n    margin: 0;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n  }\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n\n      path {\n        stroke: white;\n      }\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: white;\n    margin-left: 15px;\n  }\n  @media (min-width: ",
        ") {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 67px;\n    padding-left: 65px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n    padding-left: 80px;\n    margin-bottom: inherit;\n    justify-content: space-between;\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n\n  @media (min-width: ",
        ") {\n    flex: 0.9;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 0.8;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.2;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 60px;\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 60px;\n    flex: 0.1;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 60px;\n    flex: 0.2;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  width: 324px;\n  max-width: 400px;\n  padding-bottom: 25px;\n\n  div {\n    width: 324px;\n    max-width: 400px;\n    span {\n      display: ",
        ";\n      text-transform: ",
        ";\n      font-weight: ",
        ";\n      font-size: ",
        ";\n    }\n    span::first-letter {\n      text-transform: uppercase;\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n\n  h2 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    margin: 21px 30px 0;\n    max-width: none;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 0;\n    max-width: none;\n  }\n\n  h5 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 700;\n    color: ",
        ";\n    margin: 8px 30px 0;\n    max-width: none;\n  }\n\n  div {\n    button {\n      margin-left: 30px;\n    }\n  }\n\n  .text {\n    div {\n      font-family: ",
        ";\n      ",
        "\n      font-weight: 400;\n      color: ",
        ";\n      margin: 8px 30px 0;\n      max-width: none;\n    }\n\n    span {\n      font-weight: 400;\n      color: ",
        ";\n      align-items: baseline;\n    }\n  }\n\n  .text-app {\n    div {\n      font-family: ",
        ";\n      ",
        "\n      font-weight: 400;\n      color: ",
        ";\n      margin: 8px 30px 0;\n      max-width: none;\n    }\n\n    span {\n      font-family: ",
        ";\n      font-weight: 700;\n      color: ",
        ";\n      align-items: baseline;\n    }\n  }\n\n  .price {\n    h4 {\n      margin: inherit;\n      font-size: inherit;\n      font-family: inherit;\n      line-height: inherit;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 32px;\n    padding-top: ",
        ";\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      max-width: 620px;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n      max-width: 70%;\n    }\n\n    .text {\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding-left: 50px;\n    padding-top: ",
        ";\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      max-width: 670px;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n      max-width: 70%;\n    }\n\n    .text {\n      div {\n        ",
        "\n        max-width: 70%;\n      }\n    }\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 30px;\n  padding-bottom: 30px;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin-top: 20px;\n  margin-right: 20px;\n  display: flex;\n  height: 32px;\n  width: fit-content;\n  border-radius: 20px;\n  background-color: #ffffff;\n  padding: 4px 16px 4px 4px;\n  gap: 8px;\n  flex-direction: row-reverse;\n\n  @media (min-width: ",
        ") {\n    height: 34px;\n    margin-left: auto;\n  }\n  @media (min-width: ",
        ") {\n    margin-top: 0;\n    margin-right: 80px;\n    margin-left: auto;\n  }\n\n  &:hover {\n    opacity: 0.8;\n  }\n  &:active {\n    opacity: 0.6;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  letter-spacing: 0.5px;\n  font-size: 10px;\n  line-height: 16px;\n\n  @media (min-width: ",
        ") {\n    font-size: 18px;\n    line-height: 24px;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  border-radius: 50%;\n  border: hidden;\n  min-width: 26px;\n  min-height: 26px;\n  background-color: #e60000;\n  color: #ffffff;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  padding: 24px 0 0 0;\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  margin: 48px 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-content: center;\n  align-self: center;\n  width: fit-content;\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        "!important;\n  @media (min-width: ",
        ") {\n    font-size: 70px !important;\n  }\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  font-size: ",
        ";\n  color: ",
        ";\n  margin: 21px 30px 0;\n  max-width: none;\n"
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n font-family: ",
        ";\n font-size: ",
        ";\n  color: ",
        ";\n  margin: 21px 30px 0;\n  max-width: none;\n"
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  margin-left: 30px;\n  margin-bottom: 21px;\n  border-radius: 13px;\n  width: fit-content;\n  padding: 4px 12px;\n  span {\n    color: ",
        ";\n    align-items: center;\n    font-weight: 700;\n    ",
        "\n  }\n"
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
export var SlideProductContainer = styled.div.withConfig({
    componentId: "sc-13aa7216-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-13aa7216-1"
})(_templateObject1(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
}, breakpoints.tablet, breakpoints.desktop);
export var SlideProductContainerVideo = styled.div.withConfig({
    componentId: "sc-13aa7216-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
export var SlideProductNavigation = styled.div.withConfig({
    componentId: "sc-13aa7216-3"
})(_templateObject3(), function(param) {
    var isHeaderLiteMode = param.isHeaderLiteMode;
    return isHeaderLiteMode ? "70px" : "24px";
}, fonts.regular, pxToCssFontSize(16), breakpoints.mobile, breakpoints.tablet, breakpoints.desktop, function(param) {
    var isHeaderLiteMode = param.isHeaderLiteMode;
    return isHeaderLiteMode ? "100px" : "56px";
});
export var SlideProductContent = styled.div.withConfig({
    componentId: "sc-13aa7216-4"
})(_templateObject4());
export var SlideProductContentTop = styled.div.withConfig({
    componentId: "sc-13aa7216-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContentBottom = styled.div.withConfig({
    componentId: "sc-13aa7216-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-13aa7216-7"
})(_templateObject7(), function(param) {
    var isApp = param.isApp;
    return isApp ? "inline-block" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "lowercase" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "400" : "";
}, function(param) {
    var isApp = param.isApp;
    return isApp ? "16px" : "";
});
export var SlideProductText = styled.div.withConfig({
    componentId: "sc-13aa7216-8"
})(_templateObject8(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(42, 40), colors.$ffffff, fonts.exbold, pxToCssFont(24, 28), colors.$f2f2f2, fonts.regular, pxToCssFont(18, 24), colors.$bebebe, fonts.regular, pxToCssFont(18, 24), colors.$f2f2f2, colors.$ffffff, fonts.regular, pxToCssFont(18, 24), colors.$f2f2f2, fonts.regular, colors.$ffffff, breakpoints.tablet, function(param) {
    var disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? "7px" : "61px";
}, pxToCssFont(18, 24), pxToCssFont(64, 80), pxToCssFont(30, 38), pxToCssFont(20, 30), pxToCssFont(14, 18), breakpoints.desktop, function(param) {
    var disablePaddingTop = param.disablePaddingTop;
    return disablePaddingTop ? "7px" : "56px";
}, pxToCssFont(18, 24), pxToCssFont(70, 65), pxToCssFont(42, 52), pxToCssFont(20, 30), pxToCssFont(18, 24));
export var SlideButtons = styled.div.withConfig({
    componentId: "sc-13aa7216-9"
})(_templateObject9());
export var TargetContainer = styled.div.withConfig({
    componentId: "sc-13aa7216-10"
})(_templateObject10(), breakpoints.tablet, breakpoints.desktop);
export var TargetText = styled.div.withConfig({
    componentId: "sc-13aa7216-11"
})(_templateObject11(), fonts.regular, breakpoints.tablet);
export var CircleButton = styled.div.withConfig({
    componentId: "sc-13aa7216-12"
})(_templateObject12());
export var OverlayMain = styled.div.withConfig({
    componentId: "sc-13aa7216-13"
})(_templateObject13());
export var OverlayTitle = styled.div.withConfig({
    componentId: "sc-13aa7216-14"
})(_templateObject14(), fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var OverlayBody = styled.div.withConfig({
    componentId: "sc-13aa7216-15"
})(_templateObject15(), fonts.regular);
export var ButtonOverlayContainer = styled.div.withConfig({
    componentId: "sc-13aa7216-16"
})(_templateObject16());
export var Title = styled.h3.withConfig({
    componentId: "sc-13aa7216-17"
})(_templateObject17(), function(props) {
    if (props.text.length < 25) {
        return "52px";
    }
    if (props.text.length < 50) {
        return "42px";
    }
    return "32px";
}, breakpoints.tablet);
export var TitleApp = styled.div.withConfig({
    componentId: "sc-13aa7216-18"
})(_templateObject18(), fonts.regular, function(props) {
    if (props.text.length < 25) {
        return "52px";
    }
    if (props.text.length < 50) {
        return "42px";
    }
    return "32px";
}, colors.$ffffff);
export var LandingTitle = styled.div.withConfig({
    componentId: "sc-13aa7216-19"
})(_templateObject19(), fonts.regular, function(props) {
    if (props.text.length < 25) {
        return "52px";
    }
    if (props.text.length < 50) {
        return "42px";
    }
    return "32px";
}, colors.$ffffff);
export var PillElementApp = styled.div.withConfig({
    componentId: "sc-13aa7216-20"
})(_templateObject20(), colors.$fecb00, colors.$0d0d0d, pxToCssFont(14, 18));
