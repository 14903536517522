import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 64px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var WidgetContainer = styled.div.withConfig({
    componentId: "sc-466ff38-0"
})(_templateObject(), function(param) {
    var isLastWidget = param.isLastWidget;
    return isLastWidget ? "109px" : 0;
});
export var FormContainer = styled.div.withConfig({
    componentId: "sc-466ff38-1"
})(_templateObject1(), function(param) {
    var isLastWidget = param.isLastWidget;
    return isLastWidget ? "50px" : 0;
});
export var WidgetDivider = styled.div.withConfig({
    componentId: "sc-466ff38-2"
})(_templateObject2());
