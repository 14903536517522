import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  text-align: left;\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 8px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 12px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  .checkbox-container {\n    margin-bottom: 8px;\n    padding: 15px;\n\n    @media (min-width: ",
        ") {\n      margin-bottom: 12px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    margin-top: 38px;\n    width: 258px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 26px;\n    width: 296px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-e451eb7f-0"
})(_templateObject(), fonts.exbold, colors.$262626);
export var Title = styled.div.withConfig({
    componentId: "sc-e451eb7f-1"
})(_templateObject1(), pxToCssFont(30, 38), breakpoints.tablet, breakpoints.desktop, pxToCssFont(36, 45));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-e451eb7f-2"
})(_templateObject2(), pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30));
export var Description = styled.div.withConfig({
    componentId: "sc-e451eb7f-3"
})(_templateObject3(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var CheckboxStyle = styled.div.withConfig({
    componentId: "sc-e451eb7f-4"
})(_templateObject4(), breakpoints.tablet);
export var ButtonStyle = styled.div.withConfig({
    componentId: "sc-e451eb7f-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
