export var organizeRedirect = function(param) {
    var product = param.product, commonConfig = param.commonConfig, isAlternativeFWA = param.isAlternativeFWA;
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17;
    var buttonListFwa = isAlternativeFWA ? [
        {
            text: (product === null || product === void 0 ? void 0 : (ref = product.redirectFWA) === null || ref === void 0 ? void 0 : ref.title) || ""
        },
        {
            text: (product === null || product === void 0 ? void 0 : (ref1 = product.redirectFWA) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.alternativeOfferRedirect) === null || ref2 === void 0 ? void 0 : ref2.labelRedirect) || ""
        }, 
    ] : [
        {},
        {
            text: (product === null || product === void 0 ? void 0 : (ref3 = product.redirectFWA) === null || ref3 === void 0 ? void 0 : ref3.title) || ""
        }, 
    ];
    return {
        titleFwa: (product === null || product === void 0 ? void 0 : (ref4 = product.redirectFWA) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.card) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
        titleR2: (commonConfig === null || commonConfig === void 0 ? void 0 : (ref6 = commonConfig.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientRecognition) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.redirect) === null || ref8 === void 0 ? void 0 : ref8.title) || "",
        descriptionFwa: (product === null || product === void 0 ? void 0 : (ref9 = product.redirectFWA) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.card) === null || ref10 === void 0 ? void 0 : ref10.description) || "",
        descriptionR2: (commonConfig === null || commonConfig === void 0 ? void 0 : (ref11 = commonConfig.personalinfo) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.clientRecognition) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.redirect) === null || ref13 === void 0 ? void 0 : ref13.description) || "",
        linkRedirectFwa: (product === null || product === void 0 ? void 0 : (ref14 = product.redirectFWA) === null || ref14 === void 0 ? void 0 : ref14.url) || "",
        buttonListFwa: buttonListFwa,
        buttonListR2: [
            {},
            {
                text: (commonConfig === null || commonConfig === void 0 ? void 0 : (ref15 = commonConfig.personalinfo) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.clientRecognition) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.redirect) === null || ref17 === void 0 ? void 0 : ref17.buttonLabel) || ""
            }, 
        ]
    };
};
