import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  // max-width: 1440px;\n  margin: 0 auto;\n  padding-top: 14px !important;\n  @media (min-width: ",
        ") {\n    padding-top: 5px !important;\n  }\n  @media (min-width: ",
        ") {\n    padding-top: 48px !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: inherit;\n  transform-style: inherit;\n  perspective: inherit;\n  perspective-origin: inherit;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n  overflow-y: inherit;\n  overflow-x: inherit;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  body {\n    overscroll-behavior-y: none;\n    margin: 0;\n    padding: 0;\n    -ms-overflow-style: none; /* for Internet Explorer, Edge */\n    scrollbar-width: none;\n\n    ::-webkit-scrollbar {\n      display: none; /* for Chrome, Safari, and Opera */\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
var PageLayout = styled.div.withConfig({
    componentId: "sc-307514f4-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
var MainContainer = styled.div.withConfig({
    componentId: "sc-307514f4-1"
})(_templateObject1());
var WidgetWrapper = styled.div.withConfig({
    componentId: "sc-307514f4-2"
})(_templateObject2(), function(param) {
    var padding = param.padding;
    return "".concat(padding, "px");
});
var GlobalWrapper = createGlobalStyle(_templateObject3());
export { PageLayout, MainContainer, GlobalWrapper, WidgetWrapper };
