import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 120px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin: 0 23px 50px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 300px;\n  max-width: 400px;\n  padding-bottom: 25px;\n  margin: 0 auto;\n  margin-top: 30px;\n\n  div {\n    width: 300px;\n    max-width: 400px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  p {\n    margin-top: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  margin: 48px 23px 31px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts } from "@vfit/shared/themes";
export var TopProductContainer = styled.div.withConfig({
    componentId: "sc-986fdfca-0"
})(_templateObject());
export var ButtonDesc = styled.div.withConfig({
    componentId: "sc-986fdfca-1"
})(_templateObject1());
export var ModalButtonContainer = styled.div.withConfig({
    componentId: "sc-986fdfca-2"
})(_templateObject2());
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-986fdfca-3"
})(_templateObject3(), fonts.regular, colors.$7e7e7e);
export var DescriptionContainer = styled.div.withConfig({
    componentId: "sc-986fdfca-4"
})(_templateObject4());
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-986fdfca-5"
})(_templateObject5());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-986fdfca-6"
})(_templateObject6());
export var StickyOfferContainerTopProduct = styled.div.withConfig({
    componentId: "sc-986fdfca-7"
})(_templateObject7());
