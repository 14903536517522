import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorManager, ErrorService } from "@vfit/shared/providers";
var checkProductOfferFlow = function(product, winbackCampaignsList, cmsWinbackData) {
    var urlParams = new URLSearchParams(window.location.search);
    var clientCode = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("cC")) || (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("cc")) || "";
    var targetCampaignId = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("tC")) || (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("tc")) || "";
    var ref = (winbackCampaignsList === null || winbackCampaignsList === void 0 ? void 0 : winbackCampaignsList[clientCode]) || {}, campaignDetails = ref.campaigndetails;
    var campaignId = product.campaignId;
    var redirectHomePage = function() {
        window.location.href = "/";
    };
    var manageErrorWinback = function() {
        var ref, ref1, ref2, ref3;
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
            title: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref = cmsWinbackData.winbacknumber) === null || ref === void 0 ? void 0 : (ref1 = ref.errorPopup) === null || ref1 === void 0 ? void 0 : ref1.title) || "Ops",
            message: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref2 = cmsWinbackData.winbacknumber) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.errorPopup) === null || ref3 === void 0 ? void 0 : ref3.message) || "Si \xe8 verificato un problema. Riprovare in seguito",
            actionText: "Chiudi",
            fullscreen: true,
            disableCloseModalOnClickAction: true,
            onClose: redirectHomePage,
            actionEvent: function() {
                redirectHomePage();
            }
        });
    };
    var showExpiredCampaign = function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
        errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
            title: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref = cmsWinbackData.winbacknumber) === null || ref === void 0 ? void 0 : (ref1 = ref.expiredPopup) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            message: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref2 = cmsWinbackData.winbacknumber) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.expiredPopup) === null || ref3 === void 0 ? void 0 : ref3.message) || "",
            actionText: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref4 = cmsWinbackData.winbacknumber) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.expiredPopup) === null || ref5 === void 0 ? void 0 : ref5.ctaText) || "",
            actionEvent: function() {
                redirectHomePage();
            },
            secondButtonText: (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref6 = cmsWinbackData.winbacknumber) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.expiredPopup) === null || ref7 === void 0 ? void 0 : ref7.buttonText) || "",
            secondActionEvent: function() {
                var ref, ref1;
                window.location.href = (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref = cmsWinbackData.winbacknumber) === null || ref === void 0 ? void 0 : (ref1 = ref.expiredPopup) === null || ref1 === void 0 ? void 0 : ref1.buttonRedirect) || "";
            },
            onClose: function() {
                var ref, ref1;
                window.location.href = (cmsWinbackData === null || cmsWinbackData === void 0 ? void 0 : (ref = cmsWinbackData.winbacknumber) === null || ref === void 0 ? void 0 : (ref1 = ref.expiredPopup) === null || ref1 === void 0 ? void 0 : ref1.buttonRedirect) || "";
            },
            disableTrack: true
        });
    };
    if (campaignId && (!clientCode || !targetCampaignId)) {
        manageErrorWinback();
        return product;
    }
    if (clientCode && campaignId === clientCode) {
        var expirationDate = (campaignDetails || {}).expirationDate;
        var isExpired = !expirationDate ? true : new Date() > new Date(expirationDate);
        if (isExpired) {
            showExpiredCampaign();
            return product;
        }
        return _object_spread_props(_object_spread({}, product), {
            isWinback: true,
            cc: clientCode,
            tc: targetCampaignId
        });
    }
    return product;
};
export { checkProductOfferFlow };
