import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { formatQueryParam } from "@vfit/consumer/data-access";
import { getButtonActionByActionType, getHome } from "@vfit/shared/data-access";
import { IActionType } from "@vfit/shared/models";
import { errorManager, ErrorService } from "@vfit/shared/providers";
/**
 *
 * @param devices
 */ var getDeviceCards = function(devices) {
    return devices.map(function(device) {
        var devicedetail = device.devicedetail;
        var image = devicedetail.image, deviceBrand = devicedetail.deviceBrand, isAvailable = devicedetail.isAvailable, deviceName = devicedetail.deviceName, lowestPrice = devicedetail.lowestPrice, hub = devicedetail.hub, haveMarketingLabel = devicedetail.haveMarketingLabel, tagLine = devicedetail.tagLine, offerLabel = devicedetail.offerLabel;
        return {
            device: device,
            config: {
                image: image,
                brand: deviceBrand,
                title: deviceName,
                isOutOfStock: !isAvailable,
                outOfStockLabel: hub === null || hub === void 0 ? void 0 : hub.outOfStockLabel,
                price: lowestPrice,
                action: hub === null || hub === void 0 ? void 0 : hub.action,
                offerLabel: offerLabel,
                haveMarketingLabel: haveMarketingLabel,
                tagLine: tagLine
            }
        };
    });
};
/**
 * Method to compose url search params
 * @param model
 * @param characteristics
 */ var getDeviceUrlByModel = function(model, characteristics) {
    var ref, ref1, ref2, ref3;
    if (!model) return "";
    var idColor = model.idColor, idSize = model.idSize, displayNameColor = model.displayNameColor;
    var ref4 = characteristics || {}, colors = ref4.colors, sizes = ref4.sizes;
    if (!idColor || !idSize || (colors === null || colors === void 0 ? void 0 : (ref = colors.elements) === null || ref === void 0 ? void 0 : ref.length) === 0 || (sizes === null || sizes === void 0 ? void 0 : (ref1 = sizes.elements) === null || ref1 === void 0 ? void 0 : ref1.length) === 0) return "";
    var foundedColor = colors === null || colors === void 0 ? void 0 : (ref2 = colors.elements) === null || ref2 === void 0 ? void 0 : ref2.find(function(c) {
        return c.id == idColor;
    });
    var foundedSize = sizes === null || sizes === void 0 ? void 0 : (ref3 = sizes.elements) === null || ref3 === void 0 ? void 0 : ref3.find(function(c) {
        return c.id == idSize;
    });
    if (!(foundedSize === null || foundedSize === void 0 ? void 0 : foundedSize.value) || !(foundedColor === null || foundedColor === void 0 ? void 0 : foundedColor.value)) return "";
    return new URLSearchParams({
        color: formatQueryParam(displayNameColor || (foundedColor === null || foundedColor === void 0 ? void 0 : foundedColor.value)),
        size: formatQueryParam(foundedSize === null || foundedSize === void 0 ? void 0 : foundedSize.value)
    }).toString();
};
/**
 * Method called on click action in device card
 * @param action
 * @param device
 * @param deviceCharacteristics
 * @param genericError
 * @param router
 * @param queryClient
 */ var clickDeviceButtonAction = function(action, device, deviceCharacteristics, genericError, router, queryClient) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var push = router.push;
    var error = function() {
        var ref, ref1, ref2;
        errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
            title: (genericError === null || genericError === void 0 ? void 0 : (ref = genericError.genericerror) === null || ref === void 0 ? void 0 : ref.title) || "Attenzione",
            message: (genericError === null || genericError === void 0 ? void 0 : (ref1 = genericError.genericerror) === null || ref1 === void 0 ? void 0 : ref1.description) || "Il prodotto selezionato non \xe8 disponibile",
            actionText: (genericError === null || genericError === void 0 ? void 0 : (ref2 = genericError.genericerror) === null || ref2 === void 0 ? void 0 : ref2.buttonLabel) || "Capito",
            event: "page_error",
            event_label: "Generic Error"
        });
    };
    if (action.type != IActionType.SHOW_DETAIL) {
        getButtonActionByActionType(action, push, queryClient);
        return false;
    }
    var defaultDevice = (device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : (ref1 = ref.models) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
        return el.isDefault;
    })) || (device === null || device === void 0 ? void 0 : (ref2 = device.devicedetail) === null || ref2 === void 0 ? void 0 : ref2.models[0]);
    if (!defaultDevice) {
        error();
        return false;
    }
    var deviceUrl = getDeviceUrlByModel(defaultDevice, deviceCharacteristics);
    if (!deviceUrl) {
        error();
        return false;
    }
    var basePath = ((ref5 = window === null || window === void 0 ? void 0 : (ref3 = window.location) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.pathname) === null || ref4 === void 0 ? void 0 : ref4.split("?")) === null || ref5 === void 0 ? void 0 : ref5[0]) || "";
    var url = "".concat(getHome()).concat(basePath, "/").concat(device.devicedetail.slug, "?").concat(deviceUrl);
    // Router next push slow
    window.location.href = url;
    return false;
};
/**
 * Method to get all colors by device and char
 * @param device
 * @param deviceCharacteristics
 */ var getAllColors = function(device, deviceCharacteristics) {
    var ref;
    if (!deviceCharacteristics || !(deviceCharacteristics === null || deviceCharacteristics === void 0 ? void 0 : deviceCharacteristics.colors) || !(deviceCharacteristics === null || deviceCharacteristics === void 0 ? void 0 : deviceCharacteristics.sizes)) return [];
    var devicedetail = device.devicedetail;
    return (devicedetail === null || devicedetail === void 0 ? void 0 : (ref = devicedetail.models) === null || ref === void 0 ? void 0 : ref.map(function(dd) {
        var ref, ref1;
        return {
            exColor: dd.exColor,
            color: deviceCharacteristics === null || deviceCharacteristics === void 0 ? void 0 : (ref = deviceCharacteristics.colors) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.find(function(el) {
                return el.id == dd.idColor;
            })
        };
    })) || [];
};
/**
 * Method to update all cards height
 * @param ref
 */ var updateAllCardsHeight = function(ref) {
    // eslint-disable-next-line no-param-reassign
    (ref).style.gap = "32px";
    var allSlides = ref === null || ref === void 0 ? void 0 : ref.getElementsByClassName("card-info-container");
    var allHeight = [];
    // eslint-disable-next-line no-plusplus
    for(var i = 0; i < allSlides.length; i++){
        if (allSlides[i]) {
            var _$ref;
            allHeight.push((_$ref = allSlides[i]) === null || _$ref === void 0 ? void 0 : _$ref.clientHeight);
        }
    }
    // eslint-disable-next-line no-plusplus
    for(var i1 = 0; i1 < allSlides.length; i1++){
        var _Math;
        allSlides[i1].style.minHeight = "".concat((_Math = Math).max.apply(_Math, _to_consumable_array(allHeight)), "px");
    }
};
export { getDeviceCards, getDeviceUrlByModel, clickDeviceButtonAction, getAllColors, updateAllCardsHeight };
