import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n\n  .button-slide-container {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-top: 32px;\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  flex-direction: column;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    gap: 20px;\n  }\n\n  .button-slide-container {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 32px 20px;\n\n  @media (min-width: ",
        ") {\n    margin: 64px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 64px 55px;\n  }\n\n  .button-slide-container {\n    @media (min-width: ",
        ") {\n      position: absolute;\n      bottom: 64px;\n      left: 40px;\n      right: 40px;\n    }\n\n    @media (min-width: ",
        ") {\n      left: 55px;\n      right: 55px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
import { Paragraph, Title } from "../../../Portability/portability.style";
export var ModalWrap = styled.div.withConfig({
    componentId: "sc-94c41a56-0"
})(_templateObject());
export var ModalTitle = styled(Title).withConfig({
    componentId: "sc-94c41a56-1"
})(_templateObject1(), breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled(Paragraph).withConfig({
    componentId: "sc-94c41a56-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(18, 24));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-94c41a56-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
export var ButtonWrapper = styled.div.withConfig({
    componentId: "sc-94c41a56-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
