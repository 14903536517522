import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-bottom: 40px;\n  padding: 0;\n\n  li {\n    list-style: none;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n    margin-bottom: 52px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 32px;\n    margin-bottom: 48px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 8px;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        "\n  color: #333;\n  margin-top: 18px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 32px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    padding-top: 16px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  justify-content: center;\n\n  div {\n    width: 100%;\n    max-width: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n    flex-direction: row;\n    gap: 30px;\n\n    div {\n      width: 100%;\n      max-width: 258px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      width: 100%;\n      max-width: 296px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ParagraphContainer = styled.p.withConfig({
    componentId: "sc-cca8ca71-0"
})(_templateObject());
export var Title = styled(ParagraphContainer).withConfig({
    componentId: "sc-cca8ca71-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.desktop, pxToCssFont(36, 45));
export var OffersList = styled.ul.withConfig({
    componentId: "sc-cca8ca71-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled(ParagraphContainer).withConfig({
    componentId: "sc-cca8ca71-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), colors.$0d0d0d, breakpoints.tablet, pxToCssFont(18, 24), breakpoints.desktop);
export var ParagraphBold = styled(Paragraph).withConfig({
    componentId: "sc-cca8ca71-4"
})(_templateObject4(), pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var Spacer = styled.div.withConfig({
    componentId: "sc-cca8ca71-5"
})(_templateObject5(), breakpoints.desktop);
export var ButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-cca8ca71-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.desktop);
