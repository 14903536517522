import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: ",
        ";\n\n  @media (min-width: ",
        ") {\n    min-height: 737px;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: 737px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-left: 20px;\n  margin-right: 20px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n    margin-left: 54px;\n    margin-right: 54px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n  margin-left: 20px;\n  margin-right: 20px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 64px;\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 64px;\n    margin-left: 54px;\n    margin-right: 54px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    font-weight: 400;\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 58px;\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
export var CoverageToolMapContainer = styled.div.withConfig({
    componentId: "sc-adb26b02-0"
})(_templateObject(), pxToRem(560), breakpoints.tablet, breakpoints.desktop);
export var CoverageToolMapContent = styled.div.withConfig({
    componentId: "sc-adb26b02-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var CoverageToolMapButton = styled.div.withConfig({
    componentId: "sc-adb26b02-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var Address = styled.div.withConfig({
    componentId: "sc-adb26b02-3"
})(_templateObject3(), fonts.exbold, breakpoints.mobile, pxToCssFontSize(24), breakpoints.tablet, pxToCssFontSize(30), breakpoints.desktop, pxToCssFontSize(36));
export var AddressDesc = styled.div.withConfig({
    componentId: "sc-adb26b02-4"
})(_templateObject4(), fonts.light, breakpoints.mobile, pxToCssFontSize(24), breakpoints.tablet, pxToCssFontSize(30), breakpoints.desktop, pxToCssFontSize(36));
export var MapDescription = styled.div.withConfig({
    componentId: "sc-adb26b02-5"
})(_templateObject5());
export var TextLoader = styled.div.withConfig({
    componentId: "sc-adb26b02-6"
})(_templateObject6(), colors.$ffffff, breakpoints.tablet);
