import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: -32px;\n  margin-bottom: 150px;\n\n  @media (min-width: ",
        ") {\n    margin-top: -28px;\n    margin-bottom: 150px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: -48px;\n    margin-bottom: 110px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  gap: 16px;\n\n  div {\n    width: 100%;\n  }\n\n  @media (max-width: ",
        ") {\n    max-width: 80%;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      width: 290px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    flex-flow: row;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
import { Wrapper } from "../../fallbackModal.style";
export var DetailsWrapper = styled(Wrapper).withConfig({
    componentId: "sc-f8179fb-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-f8179fb-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
