import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { CMS_CONFIG, useCmsConfig } from "@vfit/consumer/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { API, PAGES } from "@vfit/shared/data-access";
import { COVERAGE_TOOL_FLOW } from "./coverageToolModal.flow";
var getCoverageToolFlow = function() {
    var isLockIn = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false, isApp = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, isLogged = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    if (isLockIn && !isApp && !isLogged) {
        return COVERAGE_TOOL_FLOW.LOCKIN;
    }
    return COVERAGE_TOOL_FLOW.DEFAULT;
};
var coverageToolLockInMessage = function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25;
    var dataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_COVERAGE_TOOL_CONFIG);
    var errorNoOffer = {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.coveragetool) === null || ref === void 0 ? void 0 : (ref1 = ref.lockIn) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.modalErrorNoOffer) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.coveragetool) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.lockIn) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.modalErrorNoOffer) === null || ref5 === void 0 ? void 0 : ref5.description) || "",
        editLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.coveragetool) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.lockIn) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.modalErrorNoOffer) === null || ref8 === void 0 ? void 0 : ref8.editLabel) || "",
        submitLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.coveragetool) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.lockIn) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.modalErrorNoOffer) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.customAction) === null || ref12 === void 0 ? void 0 : ref12.title) || ""
    };
    var errorNoCoverage = {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref13 = dataFromCms.coveragetool) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.lockIn) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.modalErrorNoCoverage) === null || ref15 === void 0 ? void 0 : ref15.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref16 = dataFromCms.coveragetool) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.lockIn) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.modalErrorNoCoverage) === null || ref18 === void 0 ? void 0 : ref18.description) || "",
        editLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref19 = dataFromCms.coveragetool) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.lockIn) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.modalErrorNoCoverage) === null || ref21 === void 0 ? void 0 : ref21.editLabel) || "",
        submitLabel: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref22 = dataFromCms.coveragetool) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.lockIn) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.modalErrorNoCoverage) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.clickToCall) === null || ref25 === void 0 ? void 0 : ref25.title) || ""
    };
    return {
        errorNoOffer: errorNoOffer,
        errorNoCoverage: errorNoCoverage
    };
};
var organizeFlowsAppend = function(flowCards, enableAppend, idFromStep, appendSteps) {
    var indexAppend = flowCards.findIndex(function(el) {
        return el.title === idFromStep;
    });
    var organizedFlows = flowCards.slice(0, indexAppend + 1);
    var lastFlows = flowCards.slice(indexAppend + 1);
    if (enableAppend) {
        var alreadyPresent = appendSteps[0].title === lastFlows[0].title;
        if (alreadyPresent) return flowCards;
        organizedFlows = organizedFlows.concat(appendSteps);
        organizedFlows = organizedFlows.concat(lastFlows);
    } else if (lastFlows[0].title === appendSteps[0].title) {
        var removedAppendInfo = lastFlows.slice(appendSteps.length);
        organizedFlows = organizedFlows.concat(removedAppendInfo);
    } else if ((appendSteps === null || appendSteps === void 0 ? void 0 : appendSteps.findIndex(function(aS) {
        return aS.title === lastFlows[0].title;
    })) > -1) {
        var removedAppendInfo1 = lastFlows.slice(1);
        organizedFlows = organizedFlows.concat(removedAppendInfo1);
    } else {
        organizedFlows = organizedFlows.concat(lastFlows);
    }
    return organizedFlows;
};
var getAddress = function() {
    var ref, ref1, ref2, ref3, ref4;
    var createdAddressSaved = localStorage.getItem("createdAddress");
    var createdAddress = !!createdAddressSaved && JSON.parse(createdAddressSaved);
    LoggerInstance.debug("createdAddress : ", createdAddress);
    if (!createdAddress) return undefined;
    var dataToDisplay = [
        "".concat(((ref = createdAddress) === null || ref === void 0 ? void 0 : ref.street) || "", " ").concat(((ref1 = createdAddress) === null || ref1 === void 0 ? void 0 : ref1.streetNumber) || ""),
        "".concat(((ref2 = createdAddress) === null || ref2 === void 0 ? void 0 : ref2.postalCode) || "", " ").concat((ref3 = createdAddress) === null || ref3 === void 0 ? void 0 : ref3.city),
        "".concat(((ref4 = createdAddress) === null || ref4 === void 0 ? void 0 : ref4.stateOrProvince) || ""), 
    ];
    return _object_spread_props(_object_spread({}, createdAddress), {
        displayedAddress: dataToDisplay.join(", ")
    });
};
var getTrackError = function(error, step) {
    var ref, ref1;
    var pageError = "\n  ".concat((error === null || error === void 0 ? void 0 : (ref = error.error) === null || ref === void 0 ? void 0 : ref.url) || "", "_\n  ").concat((error === null || error === void 0 ? void 0 : (ref1 = error.error) === null || ref1 === void 0 ? void 0 : ref1.status) || "", "_\n  ").concat((error === null || error === void 0 ? void 0 : error.errorType) || "", "_\n  ").concat((error === null || error === void 0 ? void 0 : error.isBlocking) ? "blk" : "nblk", "_\n  ").concat(step || "");
    return {
        event_name: "page_error",
        event_label_track: "page_error",
        event_category: "error",
        page_error: pageError,
        page_error_code: (error === null || error === void 0 ? void 0 : error.errorCode) || "",
        page_type: "error page"
    };
};
export { getCoverageToolFlow, organizeFlowsAppend, getAddress, coverageToolLockInMessage, getTrackError };
