import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-consumer-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { handleEnv } from "@vfit/shared/providers";
export var encryptSimSerialNumber = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(serialNumber) {
        var textAsBuffer, hashBuffer, hashArray, digest;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    textAsBuffer = new TextEncoder().encode(serialNumber);
                    _ctx.next = 3;
                    return window.crypto.subtle.digest("SHA-256", textAsBuffer);
                case 3:
                    hashBuffer = _ctx.sent;
                    hashArray = Array.from(new Uint8Array(hashBuffer));
                    digest = hashArray.map(function(b) {
                        return b.toString(16).padStart(2, "0");
                    }).join("");
                    return _ctx.abrupt("return", digest);
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function encryptSimSerialNumber(serialNumber) {
        return _ref.apply(this, arguments);
    };
}();
export var getRedirectUrl = function(id, encryptedSim) {
    // PRE URL
    // const baseUrl = 'https://attivasim-pre.vodafone.it';
    var baseUrl = handleEnv("NX_VR_URL") || "";
    return baseUrl.concat("/ec/", "".concat(id), "?", "param=".concat(encryptedSim, "&icmp=cbu_web_landingIT"));
};
