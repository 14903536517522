import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { setTrackLink } from "@vfit/shared/data-access";
var TaggingProductDetail = function(param) {
    var taggingOptions = param.taggingOptions, product = param.product;
    var client = useQueryClient();
    var urlParams = new URLSearchParams(window.location.search);
    var isOpenCoverageTool = Boolean(urlParams.get("act"));
    var pageProduct = SoftManagerService(client).getPageProduct(product);
    var trackView = useTracking({
        event: [
            "product_view"
        ],
        event_label: SoftManagerService(client).getPromoLabel((product === null || product === void 0 ? void 0 : product.slug) || ""),
        opts: _object_spread_props(_object_spread({}, taggingOptions), {
            product_category: pageProduct.product_category || ""
        }),
        pageProduct: pageProduct,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isOpenCoverageTool
    }).trackView;
    setTrackLink(trackView);
    return null;
};
export default TaggingProductDetail;
