import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
import { setTrackLink, setTrackView } from "@vfit/shared/data-access";
var TaggingHubApp = function(param) {
    var taggingOptions = param.taggingOptions, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var client = useQueryClient();
    var trackView = useTracking({
        event: [
            (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.event) || "view"
        ],
        event_label: (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.eventLabel) || "",
        opts: _object_spread({}, taggingOptions),
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    }).trackView;
    setTrackView(trackView);
    setTrackLink(trackView);
    return null;
};
export default TaggingHubApp;
