import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 24px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-bottom: 64px;\n  border-bottom: 1px solid ",
        ";\n\n  #sticky-offer-device {\n    #sticky-offer-next {\n      button {\n        padding: 10.5px 10px;\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-right: 64px;\n    margin-left: 64px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 32px 80px 80px;\n    padding-bottom: 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n\n  .back {\n    width: inherit;\n    display: flex;\n    cursor: pointer;\n    margin: 0;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n  }\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n\n      path {\n        stroke: black;\n      }\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    line-height: 18px;\n    margin-left: 15px;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n      line-height: 22px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 80px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 60%;\n    margin-left: 32px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  div {\n    margin: 0;\n    span {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  line-height: 28px;\n  color: ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    line-height: 32px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  line-height: 36px;\n  color: ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    line-height: 48px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  line-height: 36px;\n  color: ",
        ";\n  margin: 0;\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    line-height: 48px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  line-height: 22px;\n  color: ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  line-height: 22px;\n  color: ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  p {\n    margin: 0;\n  }\n\n  .discount div div {\n    padding: 6px 8px;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  padding-top: 16px;\n  display: flex;\n  flex-direction: column;\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  padding-top: 16px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .additionalOfferDetails {\n    width: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    .additionalOfferDetails {\n      div {\n        display: flex;\n        justify-content: flex-start;\n      }\n    }\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-left: 16px;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  background: ",
        ";\n  border-radius: 100px;\n  color: white;\n  width: 26px;\n  height: 26px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  ",
        "\n  font-family: ",
        ";\n\n  &:hover {\n    background: ",
        ";\n  }\n\n  &:active {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-top: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n  }\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n\n  img {\n    object-fit: contain !important;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-right: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 40%;\n    margin-right: 32px;\n  }\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n\n  @media (min-width: ",
        ") {\n    grid-column-start: 2;\n    gap: 40px;\n  }\n"
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  p {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    line-height: 24px;\n    font-weight: 700;\n    color: ",
        ";\n    margin: 0;\n  }\n"
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 16px;\n  column-gap: 24px;\n"
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  min-height: 54px;\n  min-width: 100px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 8px;\n\n  p {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    font-weight: ",
        ";\n    ",
        "\n    line-height: 18px;\n    text-align: center;\n  }\n\n  &:hover {\n    p {\n      font-weight: 700;\n    }\n  }\n"
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
function _templateObject22() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 100%;\n  gap: 8px;\n  justify-content: space-between;\n  padding: 16px 20px;\n  flex-direction: column;\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject22 = function _templateObject22() {
        return data;
    };
    return data;
}
function _templateObject23() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  text-align: start;\n\n  .titleBoxOffer {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: ",
        ";\n    ",
        "\n    line-height: 32px;\n    color: ",
        ";\n  }\n  p {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: ",
        ";\n    ",
        "\n    line-height: 22px;\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject23 = function _templateObject23() {
        return data;
    };
    return data;
}
function _templateObject24() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    p {\n      margin: 0;\n      font-family: ",
        ";\n      color: ",
        ";\n      @media (min-width: ",
        ") {\n        font-weight: 400;\n      }\n    }\n  }\n"
    ]);
    _templateObject24 = function _templateObject24() {
        return data;
    };
    return data;
}
function _templateObject25() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  text-align: start;\n\n  div {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: ",
        ";\n    ",
        "\n    line-height: 22px;\n    color: ",
        ";\n    text-align: start;\n  }\n  @media (min-width: ",
        ") {\n    align-items: flex-end;\n    div {\n      text-align: end;\n    }\n  }\n"
    ]);
    _templateObject25 = function _templateObject25() {
        return data;
    };
    return data;
}
function _templateObject26() {
    var data = _tagged_template_literal([
        "\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  border: 1px solid ",
        ";\n  background-color: ",
        ";\n"
    ]);
    _templateObject26 = function _templateObject26() {
        return data;
    };
    return data;
}
function _templateObject27() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  align-items: flex-start;\n  justify-content: flex-start;\n"
    ]);
    _templateObject27 = function _templateObject27() {
        return data;
    };
    return data;
}
function _templateObject28() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: 'flex-start';\n  align-items: baseline;\n  margin: 0;\n  margin-bottom: 8px;\n\n  svg {\n    margin-right: 16px;\n    width: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject28 = function _templateObject28() {
        return data;
    };
    return data;
}
function _templateObject29() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n  }\n"
    ]);
    _templateObject29 = function _templateObject29() {
        return data;
    };
    return data;
}
function _templateObject30() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject30 = function _templateObject30() {
        return data;
    };
    return data;
}
function _templateObject31() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-left: 16px;\n  border: none;\n  padding: 0;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  background: ",
        ";\n  border-radius: 100px;\n  color: white;\n  width: 26px;\n  height: 26px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  ",
        "\n  font-family: ",
        ";\n\n  &:hover {\n    background: ",
        ";\n  }\n\n  &:active {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject31 = function _templateObject31() {
        return data;
    };
    return data;
}
function _templateObject32() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject32 = function _templateObject32() {
        return data;
    };
    return data;
}
function _templateObject33() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject33 = function _templateObject33() {
        return data;
    };
    return data;
}
function _templateObject34() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 16px;\n\n  div {\n    width: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    justify-content: flex-start;\n    gap: 32px;\n\n    div {\n      width: 256px;\n    }\n  }\n"
    ]);
    _templateObject34 = function _templateObject34() {
        return data;
    };
    return data;
}
function _templateObject35() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject35 = function _templateObject35() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
import styled from "styled-components";
export var SlideDeviceContent = styled.div.withConfig({
    componentId: "sc-8c81e011-0"
})(_templateObject(), colors.$bebebe, breakpoints.tablet, breakpoints.desktop);
export var SlideDeviceNavigation = styled.div.withConfig({
    componentId: "sc-8c81e011-1"
})(_templateObject1(), fonts.regular, pxToCssFontSize(14), breakpoints.tablet, pxToCssFontSize(16), breakpoints.desktop);
export var ContainerTopDevice = styled.div.withConfig({
    componentId: "sc-8c81e011-2"
})(_templateObject2(), breakpoints.tablet);
// device info
export var ContainerDeviceInfo = styled.div.withConfig({
    componentId: "sc-8c81e011-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var ContainerTags = styled.div.withConfig({
    componentId: "sc-8c81e011-4"
})(_templateObject4());
export var DeviceInfo = styled.div.withConfig({
    componentId: "sc-8c81e011-5"
})(_templateObject5());
export var DeviceBrand = styled.p.withConfig({
    componentId: "sc-8c81e011-6"
})(_templateObject6(), fonts.light, pxToCssFontSize(20), colors.$0d0d0d, breakpoints.tablet, pxToCssFontSize(22));
export var DeviceTitle = styled.p.withConfig({
    componentId: "sc-8c81e011-7"
})(_templateObject7(), fonts.exbold, pxToCssFontSize(28), colors.$0d0d0d, breakpoints.tablet, pxToCssFontSize(36));
export var DeviceSubTitle = styled.p.withConfig({
    componentId: "sc-8c81e011-8"
})(_templateObject8(), fonts.light, pxToCssFontSize(28), colors.$0d0d0d, breakpoints.tablet, pxToCssFontSize(36));
export var DeviceFrontLabel = styled.p.withConfig({
    componentId: "sc-8c81e011-9"
})(_templateObject9(), fonts.regular, pxToCssFontSize(16), colors.$262626);
export var DeviceShortDescription = styled.p.withConfig({
    componentId: "sc-8c81e011-10"
})(_templateObject10(), fonts.regular, pxToCssFontSize(16), colors.$7e7e7e);
export var DevicePrice = styled.div.withConfig({
    componentId: "sc-8c81e011-11"
})(_templateObject11(), pxToCssFont(12, 16));
export var DeviceDetailOffer = styled.div.withConfig({
    componentId: "sc-8c81e011-12"
})(_templateObject12());
export var DeviceSpecContainer = styled.div.withConfig({
    componentId: "sc-8c81e011-13"
})(_templateObject13(), breakpoints.desktop);
export var DeviceSpecTitle = styled.p.withConfig({
    componentId: "sc-8c81e011-14"
})(_templateObject14(), fonts.regular, pxToCssFontSize(14), colors.$262626);
export var DeviceSpecButton = styled.button.withConfig({
    componentId: "sc-8c81e011-15"
})(_templateObject15(), colors.$e60000, pxToCssFontSize(26), fonts.light, colors.$bd0000, colors.$a10000);
export var DeviceCustomizeContainer = styled.div.withConfig({
    componentId: "sc-8c81e011-16"
})(_templateObject16(), breakpoints.desktop);
// device info
export var ContainerDeviceImage = styled.div.withConfig({
    componentId: "sc-8c81e011-17"
})(_templateObject17(), breakpoints.tablet, breakpoints.desktop);
export var ContainerDeviceCustomize = styled.div.withConfig({
    componentId: "sc-8c81e011-18"
})(_templateObject18(), breakpoints.desktop);
export var DeviceCustomize = styled.div.withConfig({
    componentId: "sc-8c81e011-19"
})(_templateObject19(), fonts.regular, pxToCssFontSize(18), colors.$333333);
export var ListItem = styled.div.withConfig({
    componentId: "sc-8c81e011-20"
})(_templateObject20());
export var Box = styled.div.withConfig({
    componentId: "sc-8c81e011-21"
})(_templateObject21(), fonts.regular, function(param) {
    var selected = param.selected;
    return selected ? 700 : 400;
}, pxToCssFontSize(14));
export var OffertBox = styled.div.withConfig({
    componentId: "sc-8c81e011-22"
})(_templateObject22(), breakpoints.desktop);
export var OfferBoxRight = styled.div.withConfig({
    componentId: "sc-8c81e011-23"
})(_templateObject23(), function(param) {
    var selected = param.selected;
    return selected ? fonts.exbold : fonts.regular;
}, function(param) {
    var selected = param.selected;
    return selected ? 700 : 400;
}, pxToCssFontSize(22), colors.$0d0d0d, function(param) {
    var selected = param.selected;
    return selected ? fonts.exbold : fonts.regular;
}, function(param) {
    var selected = param.selected;
    return selected ? 700 : 400;
}, pxToCssFontSize(16), colors.$0d0d0d);
export var OfferBoxDesc = styled.div.withConfig({
    componentId: "sc-8c81e011-24"
})(_templateObject24(), fonts.regular, colors.$0d0d0d, breakpoints.tablet);
export var OfferBoxLeft = styled.div.withConfig({
    componentId: "sc-8c81e011-25"
})(_templateObject25(), fonts.regular, function(param) {
    var selected = param.selected;
    return selected ? 700 : 400;
}, pxToCssFontSize(16), colors.$0d0d0d, breakpoints.desktop);
export var ColorItem = styled.div.withConfig({
    componentId: "sc-8c81e011-26"
})(_templateObject26(), colors.$bebebe, function(param) {
    var itemColor = param.itemColor;
    return itemColor;
});
export var DeviceCardSpecContainer = styled.div.withConfig({
    componentId: "sc-8c81e011-27"
})(_templateObject27());
export var DeviceCardSpecItem = styled.div.withConfig({
    componentId: "sc-8c81e011-28"
})(_templateObject28(), breakpoints.tablet, breakpoints.desktop);
export var DeviceCardSpecText = styled.div.withConfig({
    componentId: "sc-8c81e011-29"
})(_templateObject29(), fonts.regular, pxToCssFontSize(14), colors.$262626);
export var DeviceCardSpecTitle = styled.p.withConfig({
    componentId: "sc-8c81e011-30"
})(_templateObject30(), fonts.regular, pxToCssFontSize(20), colors.$262626);
export var DeviceCardSpecItemAction = styled.button.withConfig({
    componentId: "sc-8c81e011-31"
})(_templateObject31(), colors.$e60000, pxToCssFontSize(26), fonts.light, colors.$bd0000, colors.$a10000);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-8c81e011-32"
})(_templateObject32(), pxToCssFont(18, 16), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-8c81e011-33"
})(_templateObject33());
export var ContainerButtons = styled.div.withConfig({
    componentId: "sc-8c81e011-34"
})(_templateObject34(), breakpoints.desktop);
export var DeviceOfferWrapper = styled.div.withConfig({
    componentId: "sc-8c81e011-35"
})(_templateObject35());
