import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px;\n\n  @media (min-width: ",
        ") {\n    padding: 0 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 54px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding-top: 32px;\n  gap: 16px;\n  display: flex;\n  flex-direction: column;\n\n  p {\n    position: relative;\n  }\n\n  @media (min-width: ",
        ") {\n    gap: 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 40px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding-top: 16px;\n  margin-bottom: 46px;\n\n  @media (min-width: ",
        ") {\n    padding-top: 12px;\n  }\n\n  button {\n    background: transparent;\n    text-align: left;\n    border: none;\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: #262626;\n    text-decoration: underline;\n    cursor: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding-top: 16px;\n\n  @media (min-width: ",
        ") {\n    padding-top: 12px;\n  }\n\n  button {\n    background: transparent;\n    text-align: left;\n    border: none;\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: #262626;\n    text-decoration: underline;\n    cursor: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 0 16px;\n  font-weight: 400;\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 60px 0 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  svg {\n    width: 25px;\n    height: 25px;\n    margin: -10px 0 0 -25px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-26be3462-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Div = styled.div.withConfig({
    componentId: "sc-26be3462-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var LinkDiv = styled.div.withConfig({
    componentId: "sc-26be3462-2"
})(_templateObject2(), breakpoints.tablet, fonts.regular, pxToCssFont(16, 22), function(param) {
    var isDisabled = param.isDisabled;
    return isDisabled ? "blocked" : "pointer";
});
export var HowToLink = styled.div.withConfig({
    componentId: "sc-26be3462-3"
})(_templateObject3(), breakpoints.tablet, fonts.regular, pxToCssFont(16, 22), function(param) {
    var isDisabled = param.isDisabled;
    return isDisabled ? "blocked" : "pointer";
});
export var Title = styled.div.withConfig({
    componentId: "sc-26be3462-4"
})(_templateObject4(), pxToCssFont(30, 38), fonts.exbold, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-26be3462-5"
})(_templateObject5(), pxToCssFont(20, 26), fonts.regular, breakpoints.tablet, pxToCssFont(20, 30));
export var ContentSpinner = styled.div.withConfig({
    componentId: "sc-26be3462-6"
})(_templateObject6());
