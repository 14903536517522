import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-96de3da6-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
