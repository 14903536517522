import * as yup from "yup";
export var fiscalCodeInfoSchema = function() {
    return yup.object({
        firstName: yup.string().required("Campo obbligatorio").matches(/^([A-zÀ-ú]{1,30})((((-||\\s||.||'||")[A-zÀ-ú]))*(\s)*?)$/, "Inserire un nome valido").test("minLen", "Inserire minimo 2 caratteri e un massimo di 30", function(val) {
            return !(val === undefined || val.trim().length < 2 || val.trim().length > 30);
        }),
        lastName: yup.string().required("Campo obbligatorio").matches(/^([A-zÀ-ú]{1,30})((((-||\\s||.||'||")[A-zÀ-ú]))*(\s)*?)$/, "Inserire un cognome valido").test("minLen", "Inserire un minimo di 2 caratteri e un massimo di 30", function(val) {
            return !(val === undefined || val.trim().length < 2 || val.trim().length > 30);
        }),
        birthDate: yup.string().required("Campo obbligatorio").test("rangeTest", "Inserire una data di nascita valida", function(value) {
            if ((value === null || value === void 0 ? void 0 : value.includes("_")) === false) {
                if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/)) {
                    var today = new Date();
                    var dmy = value.split("/");
                    var selectedDate = new Date(parseInt(dmy[2], 10), parseInt(dmy[1], 10) - 1, parseInt(dmy[0], 10));
                    if (selectedDate < today) return true;
                    return false;
                }
                return false;
            }
            return false;
        }),
        nationality: yup.string().required("Campo obbligatorio"),
        birthPlace: yup.string().required("Campo obbligatorio"),
        gender: yup.string().required("Campo obbligatorio")
    });
};
