import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: ",
        ";\n  flex-grow: 1;\n\n  @media (min-width: ",
        ") {\n    min-width: 50%;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: ",
        ";\n\n  &:not(:first-of-type) {\n    padding-top: ",
        ";\n    border-top: 1px solid ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-size: ",
        ";\n  font-weight: 400;\n  text-align: right;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: right;\n\n  p {\n    margin: 0;\n    font-size: 18px;\n    line-height: 24px;\n\n    &:not(:first-of-type) {\n      margin: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: center;\n    max-width: 40%;\n    margin: 0 auto;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 50%;\n  }\n\n  a {\n    line-height: ",
        ";\n    font-family: ",
        ";\n    font-size: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { colors, pxToRem, fonts, breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.section.withConfig({
    componentId: "sc-860aef3a-0"
})(_templateObject(), pxToRem(23, 40), colors.$bebebe, pxToRem(20), breakpoints.tablet);
export var Line = styled.div.withConfig({
    componentId: "sc-860aef3a-1"
})(_templateObject1(), pxToRem(16), pxToRem(16), colors.$262626);
export var Title = styled.span.withConfig({
    componentId: "sc-860aef3a-2"
})(_templateObject2(), fonts.light, pxToRem(20));
export var Value = styled.span.withConfig({
    componentId: "sc-860aef3a-3"
})(_templateObject3(), fonts.exbold, pxToRem(5, 0, 0));
export var BtnBox = styled.div.withConfig({
    componentId: "sc-860aef3a-4"
})(_templateObject4(), pxToRem(16), breakpoints.tablet, breakpoints.desktop, pxToRem(21.7), fonts.regular, pxToRem(14));
