import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px;\n\n  @media (min-width: ",
        ") {\n    padding: 0 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 54px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 0 0;\n  font-weight: 400;\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 60px 0 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 24px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: start;\n  ",
        ";\n  font-family: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: start;\n  ",
        ";\n  font-family: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 8px;\n  margin-bottom: 24px;\n  padding: 0;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-ca3b4b3b-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-ca3b4b3b-1"
})(_templateObject1(), pxToCssFont(30, 38), fonts.exbold, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var TextContainer = styled.div.withConfig({
    componentId: "sc-ca3b4b3b-2"
})(_templateObject2());
export var TextBold = styled.p.withConfig({
    componentId: "sc-ca3b4b3b-3"
})(_templateObject3(), pxToCssFont(20, 26), fonts.exbold);
export var Text = styled.p.withConfig({
    componentId: "sc-ca3b4b3b-4"
})(_templateObject4(), pxToCssFont(18, 24), fonts.regular);
export var List = styled.ul.withConfig({
    componentId: "sc-ca3b4b3b-5"
})(_templateObject5());
