import { deleteIsLockInIdServiceable, setIsLockInIdServiceable, BACKUP_OFFER_KEY } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export var ICheckLockin;
(function(ICheckLockin) {
    ICheckLockin[ICheckLockin["GO_TO_OFFER"] = 0] = "GO_TO_OFFER";
    ICheckLockin[ICheckLockin["NO_COVERAGE"] = 1] = "NO_COVERAGE";
    ICheckLockin[ICheckLockin["NO_OFFER_FOUND"] = 2] = "NO_OFFER_FOUND";
})(ICheckLockin || (ICheckLockin = {}));
var LOCKIN_ERROR_CODES = {
    EMPTY_CAMPAIGNS_LIST: "EMPTY_CAMPAIGNS_LIST",
    NO_USEFUL_CAMPAIGNS_FOUND: "NO_USEFUL_CAMPAIGNS_FOUND",
    CAMPAIGNS_GENERIC_ERROR: "CAMPAIGNS_GENERIC_ERROR"
};
var checkLockInOffer = function(product, lockInProduct) {
    var ref;
    var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
    var allOfferingIds = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref = offeringServiceability.offeringServiceabilityItem) === null || ref === void 0 ? void 0 : ref.map(function(el) {
        return el.offering.id;
    });
    var alternativeOffers = (product === null || product === void 0 ? void 0 : product.alternativeProductOfferList) || [];
    if (lockInProduct) {
        var isLockInIdServiceable = false;
        if ((alternativeOffers === null || alternativeOffers === void 0 ? void 0 : alternativeOffers.length) > 0) {
            var ref1;
            isLockInIdServiceable = alternativeOffers.includes(lockInProduct === null || lockInProduct === void 0 ? void 0 : (ref1 = lockInProduct.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString());
        }
        if (isLockInIdServiceable) {
            var ref2, ref3, ref4, ref5;
            var offeringServiceabilityItem = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref2 = offeringServiceability.offeringServiceabilityItem) === null || ref2 === void 0 ? void 0 : ref2.find(function(el) {
                var ref, ref1;
                return ((ref = el.offering.id) === null || ref === void 0 ? void 0 : ref.toString()) === (lockInProduct === null || lockInProduct === void 0 ? void 0 : (ref1 = lockInProduct.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString());
            });
            localStorage.setItem(BACKUP_OFFER_KEY, JSON.stringify({
                offeringId: offeringServiceabilityItem === null || offeringServiceabilityItem === void 0 ? void 0 : (ref3 = offeringServiceabilityItem.offering) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.id) === null || ref4 === void 0 ? void 0 : ref4.toString(),
                cmsId: lockInProduct === null || lockInProduct === void 0 ? void 0 : (ref5 = lockInProduct.cmsId) === null || ref5 === void 0 ? void 0 : ref5.toString()
            }));
            setIsLockInIdServiceable();
            return ICheckLockin.GO_TO_OFFER;
        }
        return ICheckLockin.NO_COVERAGE;
    }
    deleteIsLockInIdServiceable();
    var isOfferFound = false;
    alternativeOffers === null || alternativeOffers === void 0 ? void 0 : alternativeOffers.forEach(function(el) {
        if (allOfferingIds === null || allOfferingIds === void 0 ? void 0 : allOfferingIds.includes(el)) isOfferFound = true;
    });
    if (isOfferFound) {
        return ICheckLockin.GO_TO_OFFER;
    }
    return ICheckLockin.NO_OFFER_FOUND;
};
var checkCampaignEligibility = function(offeringServiceability, campaignsData) {
    var ref;
    if (campaignsData && campaignsData.length <= 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var serviceabilityIds = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref = offeringServiceability.offeringServiceabilityItem) === null || ref === void 0 ? void 0 : ref.map(function(item) {
        var ref;
        return item === null || item === void 0 ? void 0 : (ref = item.offering) === null || ref === void 0 ? void 0 : ref.id;
    });
    // FixedLine type filter campaignsOffer
    var fixedCampaignsList = (campaignsData === null || campaignsData === void 0 ? void 0 : campaignsData.filter(function(item) {
        var ref;
        return (item === null || item === void 0 ? void 0 : (ref = item.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "fixed";
    })) || [];
    if ((fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var usefulFixedCampaignsList = (fixedCampaignsList === null || fixedCampaignsList === void 0 ? void 0 : fixedCampaignsList.filter(function(item) {
        var ref;
        return serviceabilityIds.includes(item === null || item === void 0 ? void 0 : (ref = item.fixedBundle) === null || ref === void 0 ? void 0 : ref.productOfferId);
    })) || [];
    if ((usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.length) === 0) {
        return {
            eligible: false,
            errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST
        };
    }
    var lockInOffersFound = usefulFixedCampaignsList === null || usefulFixedCampaignsList === void 0 ? void 0 : usefulFixedCampaignsList.sort(function(item1, item2) {
        return parseInt(item2.score, 10) - parseInt(item1.score, 10);
    });
    if (Object.keys(lockInOffersFound).length !== 0) {
        return {
            eligible: true,
            lockInOffer: lockInOffersFound[0],
            errorCode: ""
        };
    }
    return {
        eligible: false,
        errorCode: LOCKIN_ERROR_CODES.NO_USEFUL_CAMPAIGNS_FOUND
    };
};
var getEligibilityProduct = function(allProducts, campaignsData) {
    var ref, ref1;
    var offeringServiceAbility = getFromLocalStorageByKey("offeringServiceability");
    var campaignEligibility = checkCampaignEligibility(offeringServiceAbility, campaignsData);
    var lockInProduct;
    if (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref = campaignEligibility.lockInOffer) === null || ref === void 0 ? void 0 : (ref1 = ref.fixedBundle) === null || ref1 === void 0 ? void 0 : ref1.productOfferId) lockInProduct = allProducts.find(function(el) {
        var ref;
        return el.offerId.toString() == (campaignEligibility === null || campaignEligibility === void 0 ? void 0 : (ref = campaignEligibility.lockInOffer) === null || ref === void 0 ? void 0 : ref.fixedBundle.productOfferId.toString());
    });
    return {
        campaignEligibility: campaignEligibility,
        lockInProduct: lockInProduct
    };
};
export { checkLockInOffer, LOCKIN_ERROR_CODES, getEligibilityProduct };
