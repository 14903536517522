var DEFAULT_SIZE = {
    default: {
        width: 405
    },
    tablet: {
        width: 329
    },
    mobile: {
        width: 329
    }
};
/**
 * Return the size of slide
 * @param slideIndex
 * @param isMobile
 * @param isTablet
 */ var getSlideSize = function(slideIndex, isMobile, isTablet) {
    if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
    return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};
var updateAllMobileHeight = function(ref, allMobileHeight, activeIndex) {
    var allSlides = ref === null || ref === void 0 ? void 0 : ref.getElementsByClassName("swiper-slide");
    // eslint-disable-next-line no-plusplus
    for(var i = 0; i < allSlides.length; i++){
        var pxAssign = "".concat(allMobileHeight[activeIndex], "px");
        if (i !== activeIndex) {
            pxAssign = "".concat(allMobileHeight[activeIndex] - 20, "px");
        }
        allSlides[i].style.height = pxAssign;
    }
};
var getAllHeight = function(ref) {
    var pagination = ref === null || ref === void 0 ? void 0 : ref.getElementsByClassName("swiper-pagination");
    pagination[0].style.marginBottom = "42px";
    var allSlides = ref === null || ref === void 0 ? void 0 : ref.getElementsByClassName("swiper-slide");
    var allHeight = [];
    // eslint-disable-next-line no-plusplus
    for(var i = 0; i < allSlides.length; i++){
        if (allSlides[i].firstChild) {
            var _$ref;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allHeight.push((_$ref = allSlides[i].firstChild) === null || _$ref === void 0 ? void 0 : _$ref.clientHeight);
        }
    }
    return allHeight;
};
var getTabFilters = function(products, defaultSelectedTabName) {
    var tabFilterCopy = [];
    products.forEach(function(product) {
        if (!tabFilterCopy.find(function(tab) {
            var ref;
            return tab.value === (product === null || product === void 0 ? void 0 : (ref = product.tab) === null || ref === void 0 ? void 0 : ref.value);
        })) {
            var ref;
            if ((ref = product.tab) === null || ref === void 0 ? void 0 : ref.value) {
                var ref1;
                tabFilterCopy.push({
                    isActive: false,
                    value: product === null || product === void 0 ? void 0 : (ref1 = product.tab) === null || ref1 === void 0 ? void 0 : ref1.value
                });
            }
        }
    });
    tabFilterCopy.push({
        isActive: true,
        value: defaultSelectedTabName
    });
    return tabFilterCopy;
};
export { getSlideSize, getAllHeight, updateAllMobileHeight, DEFAULT_SIZE, getTabFilters };
