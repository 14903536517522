import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  margin: 12px 44px 32px;\n\n  @media (min-width: ",
        ") {\n    margin: 21.5px 40px 34px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 29.5px 109px 64px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 20px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  color: ",
        ";\n  margin-bottom: 8px;\n  text-align: center;\n  text-transform: capitalize;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 18px;\n\n  @media (min-width: ",
        ") {\n    gap: 32px;\n  }\n\n  .linkWithIcon {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: inline-flex;\n  font-family: ",
        ";\n  background-color: transparent;\n  padding: 0;\n  margin: 0;\n  border: none;\n  border-bottom: 1px solid ",
        ";\n  color: ",
        ";\n  font-weight: 700;\n  ",
        "\n\n  &:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 26px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 36px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 42px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  color: #000;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  overflow-y: scroll;\n  height: 30vh;\n  margin-bottom: 34.5px;\n  padding-right: 10px;\n  width: calc(100% + 10px);\n  text-transform: capitalize;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 46px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  // height: 100%;\n  margin: 0 -44px 32px;\n\n  @media (min-width: ",
        ") {\n    height: auto;\n    margin: 0 -40px 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 -109px 40px;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  justify-content: space-around;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    position: relative;\n    width: 350px;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var StoreListContainer = styled.div.withConfig({
    componentId: "sc-5a30c84f-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-5a30c84f-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-5a30c84f-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, breakpoints.desktop, pxToCssFont(36, 45));
export var SubTitle = styled(Paragraph).withConfig({
    componentId: "sc-5a30c84f-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(18, 24), breakpoints.desktop, pxToCssFont(20, 30));
export var SelectedAddress = styled(Paragraph).withConfig({
    componentId: "sc-5a30c84f-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 26), colors.$e60000, breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var CtaBox = styled.div.withConfig({
    componentId: "sc-5a30c84f-5"
})(_templateObject5(), breakpoints.tablet, pxToCssFontSize(16));
export var CtaAddress = styled.button.withConfig({
    componentId: "sc-5a30c84f-6"
})(_templateObject6(), fonts.regular, colors.$262626, colors.$262626, pxToCssFont(16, 22));
export var SwitchToggleContainer = styled.div.withConfig({
    componentId: "sc-5a30c84f-7"
})(_templateObject7(), breakpoints.tablet, breakpoints.desktop);
export var LabelSwitch = styled(Paragraph).withConfig({
    componentId: "sc-5a30c84f-8"
})(_templateObject8(), fonts.regular, pxToCssFont(12, 16), breakpoints.tablet, pxToCssFont(14, 18));
export var AddressListContainer = styled.div.withConfig({
    componentId: "sc-5a30c84f-9"
})(_templateObject9(), breakpoints.tablet);
export var MapContainer = styled.div.withConfig({
    componentId: "sc-5a30c84f-10"
})(_templateObject10(), breakpoints.tablet, breakpoints.desktop);
export var WrapButton = styled.div.withConfig({
    componentId: "sc-5a30c84f-11"
})(_templateObject11());
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-5a30c84f-12"
})(_templateObject12(), breakpoints.tablet);
