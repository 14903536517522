import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  font-weight: 400;\n  max-height: 60vh;\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  align-self: flex-start;\n  overflow: scroll;\n  overflow-x: hidden;\n  margin-bottom: 32px;\n  min-height: 68px;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 24px;\n    min-height: 72px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 100%;\n  margin-bottom: 36px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 44px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 52px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  justify-content: center;\n\n  .button-slide-container {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-eab4b0b-0"
})(_templateObject(), colors.$262626);
export var Title = styled.div.withConfig({
    componentId: "sc-eab4b0b-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-eab4b0b-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, breakpoints.desktop);
export var CheckDiv = styled.div.withConfig({
    componentId: "sc-eab4b0b-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var LinkDiv = styled.div.withConfig({
    componentId: "sc-eab4b0b-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-eab4b0b-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
