import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useEffect, useState } from "react";
import { AppMobile } from "@vfit/shared/data-access";
import { ProductType, retrieveAppSession, useGetActivable } from "@vfit/consumer/data-access";
import { getAppSessionItems, getSliders, showError } from "./hubApp.utils";
export var useHubApp = function(param) {
    var page = param.page;
    var ref = useState(false), showActivationModal = ref[0], setShowActivationModal = ref[1];
    var ref1 = useState(true), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(""), userMsisdns = ref2[0], setUserMsisdn = ref2[1];
    var ref3 = useState(undefined), sliders = ref3[0], setSliders = ref3[1];
    var elements = ((page === null || page === void 0 ? void 0 : page.params) || {}).elements;
    var widgets = (elements || {}).widgets;
    var ref4 = useState({
        id: "",
        price: "",
        title: "",
        section: undefined
    }), offer = ref4[0], setOffer = ref4[1];
    var ref5 = useGetActivable(userMsisdns, ProductType.NBA, "", false), activableData = ref5.data, isErrorActivable = ref5.isError, isLoadingActivable = ref5.isLoading, isSuccessActivable = ref5.isSuccess, refetchActivable = ref5.refetch;
    var handleCloseModal = function() {
        setShowActivationModal(false);
    };
    var handleOpenActivation = function() {
        setShowActivationModal(true);
    };
    var manageActivationCatalog = function(event) {
        var ref;
        var detail = event.detail;
        var allSections = (activableData === null || activableData === void 0 ? void 0 : activableData.sections) || [];
        var section = {};
        allSections === null || allSections === void 0 ? void 0 : allSections.forEach(function(s) {
            var allProductsSection = (s === null || s === void 0 ? void 0 : s.products) || [];
            allProductsSection === null || allProductsSection === void 0 ? void 0 : allProductsSection.forEach(function(e) {
                var ref, ref1;
                var allBOIds = (e === null || e === void 0 ? void 0 : (ref = e.campaignDetail) === null || ref === void 0 ? void 0 : (ref1 = ref.components) === null || ref1 === void 0 ? void 0 : ref1.billingOffer) || [];
                if (allBOIds) {
                    var ref2, ref3;
                    var ids = allBOIds === null || allBOIds === void 0 ? void 0 : allBOIds.map(function(aBoId) {
                        var ref;
                        return aBoId === null || aBoId === void 0 ? void 0 : (ref = aBoId.id) === null || ref === void 0 ? void 0 : ref.toString();
                    });
                    if ((ids === null || ids === void 0 ? void 0 : ids.includes(detail === null || detail === void 0 ? void 0 : (ref2 = detail.id) === null || ref2 === void 0 ? void 0 : ref2.toString())) && ((ref3 = Object.keys(section)) === null || ref3 === void 0 ? void 0 : ref3.length) === 0) {
                        section = s;
                    }
                }
            });
        });
        setOffer({
            id: (detail === null || detail === void 0 ? void 0 : (ref = detail.id) === null || ref === void 0 ? void 0 : ref.toString()) || "",
            price: "",
            title: (detail === null || detail === void 0 ? void 0 : detail.title) || "",
            section: section
        });
    };
    useEffect(function() {
        setShowActivationModal(!!(offer === null || offer === void 0 ? void 0 : offer.id));
    }, [
        offer
    ]);
    useEffect(function() {
        if (sliders) setIsLoading(false);
    }, [
        sliders
    ]);
    useEffect(function() {
        if (isSuccessActivable) {
            setSliders(_to_consumable_array(getSliders((widgets === null || widgets === void 0 ? void 0 : widgets.sliders) || [], activableData)));
        } else if (isErrorActivable) {
            showError();
        }
    }, [
        isLoadingActivable,
        isErrorActivable,
        isSuccessActivable
    ]);
    useEffect(function() {
        if (userMsisdns) {
            refetchActivable();
        }
    }, [
        userMsisdns
    ]);
    useEffect(function() {
        if (activableData) {
            if (typeof document !== "undefined") {
                document.addEventListener("hubAppActivationServiceEvent", manageActivationCatalog);
            }
        }
        return function() {
            return document.removeEventListener("hubAppActivationServiceEvent", manageActivationCatalog);
        };
    }, [
        activableData
    ]);
    useEffect(function() {
        if (AppMobile.checkIsApp()) {
            retrieveAppSession();
            setTimeout(function() {
                var appSessionItems = getAppSessionItems();
                if (!appSessionItems) {
                    showError();
                }
                setUserMsisdn(appSessionItems);
            }, 1000);
        } else {
            showError();
        }
    }, []);
    return {
        offer: offer,
        showActivationModal: showActivationModal,
        isLoading: isLoading,
        sliders: sliders,
        activableData: activableData,
        handleCloseModal: handleCloseModal,
        handleOpenActivation: handleOpenActivation
    };
};
