import { setTrackLink } from "@vfit/shared/data-access";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
export var useTaggingDeviceList = function(devices) {
    var queryClient = useQueryClient();
    var pageProduct = SoftManagerService(queryClient).getSoftDevicesByDevices((devices === null || devices === void 0 ? void 0 : devices.filter(function(device) {
        var ref;
        return device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.isSellable;
    })) || []);
    var trackView = useTracking({
        event: [
            "product_view"
        ],
        opts: {
            event_category: "sales",
            page_section: "device",
            page_subsection: "catalogo",
            page_type: "device",
            journey_type: "task"
        },
        pageProduct: pageProduct,
        visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
        disableInitialTrack: true
    }).trackView;
    setTrackLink(trackView);
};
