import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { setTrackLink, setTrackView } from "@vfit/shared/data-access";
import { removeCurrency } from "@vfit/consumer/hooks";
import { retrieveProductTechnology } from "@vfit/consumer/data-access";
import { useQueryClient } from "react-query";
import { useCoverageToolModalProvider } from "../../coverageToolModal.context";
export var useCoverageToolOtpTagging = function() {
    var queryClient = useQueryClient();
    var product = useCoverageToolModalProvider().product;
    var pageProduct = {
        product_category: "fixed",
        product_id: "".concat(product === null || product === void 0 ? void 0 : product.offerId),
        product_name: product === null || product === void 0 ? void 0 : product.slug,
        product_price: (product === null || product === void 0 ? void 0 : product.price) && removeCurrency(product.price),
        product_quantity: "1"
    };
    var opts = {
        event_category: "journey",
        page_subsection: "lock-in user otp",
        page_section: "fixed offers",
        page_type: "OTP",
        journey_name: "verify lock-in",
        product_technology: retrieveProductTechnology()
    };
    var trackView = useTracking({
        event: [
            "journey"
        ],
        event_label: "lock-in OTP",
        opts: opts,
        pageProduct: pageProduct,
        visitorTrackingOpts: SoftManagerService(queryClient).getUser()
    }).trackView;
    setTrackLink(trackView, "lock-in OTP");
    setTrackView(trackView);
};
