import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { setTrackLink } from "@vfit/shared/data-access";
var TaggingHub = function(param) {
    var taggingOptions = param.taggingOptions, pageProduct = param.pageProduct, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var client = useQueryClient();
    var trackView = useTracking({
        event: [
            "product_view"
        ],
        opts: _object_spread_props(_object_spread({}, taggingOptions), {
            product_category: (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_category) || "",
            product_target_segment: Array.isArray(pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_id) ? pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_id.map(function() {
                return "consumer";
            }) : "consumer"
        }),
        pageProduct: pageProduct,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    }).trackView;
    setTrackLink(trackView);
    return null;
};
export default TaggingHub;
