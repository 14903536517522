import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
var TaggingHomepage = function(param) {
    var taggingOptions = param.taggingOptions, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var client = useQueryClient();
    useTracking({
        event: [
            (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.event) || "view"
        ],
        event_label: (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.eventLabel) || "",
        opts: taggingOptions,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    });
    return null;
};
export default TaggingHomepage;
