import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 32px 170px;\n\n\n\n  @media (max-width: ",
        ") {\n    // overflow-x: hidden;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 64px 110px;\n  }\n\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    width: 616px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  padding: 20px 0;\n  width: 100%;\n  background: ",
        ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 20px 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 32px 128px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-cd3ef0bb-0"
})(_templateObject(), breakpoints.tablet, breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-cd3ef0bb-1"
})(_templateObject1(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
