import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: left;\n  overflow: auto;\n  flex-wrap: nowrap;\n  margin-top: 80px;\n  width: fit-content;\n  cursor: pointer;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  border-bottom: ",
        ";\n  padding: 12px 24px !important;\n  align-items: center;\n  flex-shrink: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  height: 1px;\n  margin-bottom: 25px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-ebc44371-0"
})(_templateObject());
export var TabsDiv = styled.div.withConfig({
    componentId: "sc-ebc44371-1"
})(_templateObject1());
export var TabBox = styled.div.withConfig({
    componentId: "sc-ebc44371-2"
})(_templateObject2(), function(props) {
    return props.selected ? fonts.exbold : fonts.light;
}, pxToCssFont(28, 36), function(props) {
    return props.selected ? "2px solid ".concat(colors.$e60000) : "";
}, breakpoints.desktop, pxToCssFont(36, 45));
export var TabLine = styled.div.withConfig({
    componentId: "sc-ebc44371-3"
})(_templateObject3(), colors.$bebebe);
