import { purify } from "@vfit/shared/themes";
export var stickyFloatingBannerOrganize = function(cmsConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14;
    return {
        animationDelay: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref = cmsConfig.floatingbanner) === null || ref === void 0 ? void 0 : (ref1 = ref.floatingBanner) === null || ref1 === void 0 ? void 0 : ref1.animationDelay) || "",
        animationType: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.floatingbanner) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.floatingBanner) === null || ref3 === void 0 ? void 0 : ref3.animationType) || "slide-up",
        buttonCloseXColor: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.floatingbanner) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.floatingBanner) === null || ref5 === void 0 ? void 0 : ref5.buttonCloseXColor) || "#fff",
        htmlDesktop: ((ref8 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref6 = cmsConfig.floatingbanner) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.floatingBanner) === null || ref7 === void 0 ? void 0 : ref7.htmlDesktop) || ""
        ])) === null || ref8 === void 0 ? void 0 : ref8[0]) || "",
        htmlTablet: ((ref11 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref9 = cmsConfig.floatingbanner) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.floatingBanner) === null || ref10 === void 0 ? void 0 : ref10.htmlTablet) || ""
        ])) === null || ref11 === void 0 ? void 0 : ref11[0]) || "",
        htmlMobile: ((ref14 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref12 = cmsConfig.floatingbanner) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.floatingBanner) === null || ref13 === void 0 ? void 0 : ref13.htmlMobile) || ""
        ])) === null || ref14 === void 0 ? void 0 : ref14[0]) || ""
    };
};
