import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  @media(min-width: ",
        ") {\n    margin-top: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var WinbackContainer = styled.div.withConfig({
    componentId: "sc-13c85ca4-0"
})(_templateObject(), breakpoints.desktop);
