import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import CoverageToolAutoCard from "./components/CoverageToolAutoCard/coverageToolAutoCard";
import CoverageToolMapCard from "./components/CoverageToolMapCard/coverageToolMapCard";
import CoverageToolCheckNumberCard from "./components/CoverageToolCheckNumberCard/coverageToolCheckNumberCard";
import CoverageToolOtpCard from "./components/CoverageToolOtpCard/coverageToolOtpCard";
import CoverageToolManualCard from "./components/CoverageToolManualCard/coverageToolManualCard";
import CoverageToolChooseContact from "./components/CoverageToolChooseContact/coverageToolChooseContact";
export var ID_FLOWS = {
    AUTO: "auto",
    MANUAL: "Manual",
    MAP: "map",
    CHECK_USER: "checkuser",
    CHOOSE_CONTACT: "chooseContact",
    OTP: "otp"
};
export var AUTO_FLOW = {
    AUTO_CARD: [
        {
            title: ID_FLOWS.AUTO,
            component: CoverageToolAutoCard
        }, 
    ]
};
export var MANUAL_FLOW = {
    MANUAL_CARD: [
        {
            title: ID_FLOWS.MANUAL,
            component: CoverageToolManualCard
        }, 
    ]
};
export var MAP_FLOW = {
    MAP_CARD: [
        {
            title: ID_FLOWS.MAP,
            component: CoverageToolMapCard
        }, 
    ]
};
export var LOCKIN_FLOW = {
    LOCKIN_CARDS: [
        {
            title: ID_FLOWS.CHECK_USER,
            component: CoverageToolCheckNumberCard
        },
        {
            title: ID_FLOWS.CHOOSE_CONTACT,
            component: CoverageToolChooseContact
        },
        {
            title: ID_FLOWS.OTP,
            component: CoverageToolOtpCard
        }, 
    ]
};
export var EMPTY_FLOWS = {
    EMPTY: [
        {
            title: "",
            component: undefined
        },
        {
            title: "",
            component: undefined
        }, 
    ]
};
var COVERAGE_TOOL_FLOW = {
    DEFAULT: _to_consumable_array(AUTO_FLOW.AUTO_CARD).concat(_to_consumable_array(MAP_FLOW.MAP_CARD), _to_consumable_array(EMPTY_FLOWS.EMPTY)),
    LOCKIN: _to_consumable_array(AUTO_FLOW.AUTO_CARD).concat(_to_consumable_array(MAP_FLOW.MAP_CARD), _to_consumable_array(LOCKIN_FLOW.LOCKIN_CARDS), _to_consumable_array(EMPTY_FLOWS.EMPTY))
};
export { COVERAGE_TOOL_FLOW };
