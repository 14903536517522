import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n  height: 300px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    height: 370px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n    height: 540px;\n  }\n\n  .gmnoprint.gm-bundled-control {\n    margin: 0 !important;\n  }\n\n  #location-control {\n    display: block !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  width: 100%;\n  height: 64px;\n\n  @media (min-width: ",
        ") {\n    height: 72px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 3;\n  position: absolute;\n  width: fit-content;\n  margin-left: calc(100% - 48px);\n  padding-bottom: 64px;\n  display: flex;\n  flex-direction: row-reverse;\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 72px;\n  }\n\n  svg {\n    margin: -4px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  background-color: rgba(38, 38, 38, 0% 9);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 13px 16px;\n  height: 53px;\n\n  @media (min-width: ",
        ") {\n    padding: 17px 16px;\n    height: 40px;\n  }\n\n  .posteIcon,\n  .vodafoneStoreIcon {\n    margin-right: 16px;\n    width: 27px;\n    height: 27px;\n\n    @media (min-width: ",
        ") {\n      width: 35px;\n      height: 35px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n  text-transform: capitalize;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  p {\n    margin: 0 auto;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MapContainer = styled.div.withConfig({
    componentId: "sc-a0e5b70e-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Banner = styled.div.withConfig({
    componentId: "sc-a0e5b70e-1"
})(_templateObject1(), breakpoints.tablet);
export var LocationIcon = styled.div.withConfig({
    componentId: "sc-a0e5b70e-2"
})(_templateObject2(), breakpoints.tablet);
export var Current = styled.div.withConfig({
    componentId: "sc-a0e5b70e-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.tablet);
export var Detail = styled.div.withConfig({
    componentId: "sc-a0e5b70e-4"
})(_templateObject4(), fonts.regular, colors.$ffffff, pxToCssFont(14, 19), breakpoints.tablet, pxToCssFont(16, 22), breakpoints.desktop, pxToCssFont(18, 24));
