import { TechnologyCategories } from "@vfit/consumer/data-access";
export var organizeTechnologyCardModal = function(cmsTechConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
    var dataToReturn = {
        title: (cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref = cmsTechConfig.technology) === null || ref === void 0 ? void 0 : (ref1 = ref.card) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        subtitle: (cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref2 = cmsTechConfig.technology) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.detail) === null || ref3 === void 0 ? void 0 : ref3.subtitle) || "",
        firstDescription: (cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref4 = cmsTechConfig.technology) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.detail) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.description) === null || ref6 === void 0 ? void 0 : ref6.textSimple) || "",
        secondDescription: (cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref7 = cmsTechConfig.technology) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.detail) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.description) === null || ref9 === void 0 ? void 0 : ref9.textHtml) || "",
        imageSrc: (cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref10 = cmsTechConfig.technology) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.detail) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.description) === null || ref12 === void 0 ? void 0 : ref12.image) || "",
        accordions: cmsTechConfig === null || cmsTechConfig === void 0 ? void 0 : (ref13 = cmsTechConfig.technology) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.detail) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.accordion) === null || ref15 === void 0 ? void 0 : ref15.map(function(accordion) {
            return {
                description: (accordion === null || accordion === void 0 ? void 0 : accordion.description) || "",
                title: (accordion === null || accordion === void 0 ? void 0 : accordion.title) || ""
            };
        })
    };
    return dataToReturn;
};
export var organizeTechCardsProductDetail = function(aemTechnologyData, tecnologyMappedCategory, devices) {
    var filteredDevices = [];
    var organizeItem = function(device) {
        var ref, ref1, ref2, ref3, ref4, ref5;
        return {
            title: (device === null || device === void 0 ? void 0 : (ref = device.card) === null || ref === void 0 ? void 0 : ref.title) || "",
            description: (device === null || device === void 0 ? void 0 : (ref1 = device.card) === null || ref1 === void 0 ? void 0 : ref1.description) || "",
            // eslint-disable-next-line no-unsafe-optional-chaining
            id: +(device === null || device === void 0 ? void 0 : device.id),
            image: (device === null || device === void 0 ? void 0 : (ref2 = device.card) === null || ref2 === void 0 ? void 0 : ref2.image) || "",
            showInfoLine: false,
            action: {
                title: (device === null || device === void 0 ? void 0 : (ref3 = device.card) === null || ref3 === void 0 ? void 0 : ref3.buttonLabel) || "",
                descriptionModal: (device === null || device === void 0 ? void 0 : (ref4 = device.detail) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.description) === null || ref5 === void 0 ? void 0 : ref5.textHtml) || ""
            },
            detail: device.detail
        };
    };
    if (aemTechnologyData) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
        filteredDevices.push({
            title: (aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref = aemTechnologyData.technology) === null || ref === void 0 ? void 0 : (ref1 = ref.card) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            description: (aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref2 = aemTechnologyData.technology) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.card) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
            // eslint-disable-next-line no-unsafe-optional-chaining
            id: +(aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref4 = aemTechnologyData.technology) === null || ref4 === void 0 ? void 0 : ref4.id),
            image: (aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref5 = aemTechnologyData.technology) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.card) === null || ref6 === void 0 ? void 0 : ref6.image) || "",
            showInfoLine: true,
            action: {
                title: (aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref7 = aemTechnologyData.technology) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.card) === null || ref8 === void 0 ? void 0 : ref8.buttonLabel) || "",
                descriptionModal: (aemTechnologyData === null || aemTechnologyData === void 0 ? void 0 : (ref9 = aemTechnologyData.technology) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.detail) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.description) === null || ref11 === void 0 ? void 0 : ref11.textHtml) || ""
            }
        });
    }
    Object.keys(devices).forEach(function(keyDevice) {
        var deviceItem = devices[keyDevice];
        switch(tecnologyMappedCategory){
            case TechnologyCategories.FTTH:
                var ref, ref1;
                if (((ref = deviceItem.device) === null || ref === void 0 ? void 0 : (ref1 = ref.visibility) === null || ref1 === void 0 ? void 0 : ref1.showInFtth) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FTTC:
                var ref2, ref3;
                if (((ref2 = deviceItem.device) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.visibility) === null || ref3 === void 0 ? void 0 : ref3.showInFttc) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.ADSL:
                var ref4, ref5;
                if (((ref4 = deviceItem.device) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.visibility) === null || ref5 === void 0 ? void 0 : ref5.showInAdsl) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FWAINDOOR:
                var ref6, ref7;
                if (((ref6 = deviceItem.device) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.visibility) === null || ref7 === void 0 ? void 0 : ref7.showInFwaIndoor) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FWAOUTDOOR:
                var ref8, ref9;
                if (((ref8 = deviceItem.device) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.visibility) === null || ref9 === void 0 ? void 0 : ref9.showInFwaOutdoor) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FWAOUTDOOR5G:
                var ref10, ref11;
                if (((ref10 = deviceItem.device) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.visibility) === null || ref11 === void 0 ? void 0 : ref11.showInFwaOutdoor5G) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FTTHTIM:
                var ref12, ref13;
                if (((ref12 = deviceItem.device) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.visibility) === null || ref13 === void 0 ? void 0 : ref13.showInFtthTim) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            case TechnologyCategories.FWAINDOOR5G:
                var ref14, ref15;
                if (((ref14 = deviceItem.device) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.visibility) === null || ref15 === void 0 ? void 0 : ref15.showInFwaIndoor5G) === "true") {
                    filteredDevices.push(organizeItem(deviceItem.device));
                }
                break;
            default:
                break;
        }
    });
    return filteredDevices;
};
