export var REGION;
(function(REGION) {
    REGION["ITALIA"] = "Italia";
    REGION["FOREIGN_COUNTRY"] = "Estero";
})(REGION || (REGION = {}));
export var GENDER;
(function(GENDER) {
    GENDER["MALE"] = "Maschio";
    GENDER["FEMALE"] = "Femmina";
})(GENDER || (GENDER = {}));
