import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: 36px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  p {\n    margin: 0;\n  }\n  img {\n    object-fit: contain !important;\n  }\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    width: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    width: inherit;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding-top: 14px;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n  background-color: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-e5bc488c-0"
})(_templateObject());
export var CardInfoContainer = styled.div.withConfig({
    componentId: "sc-e5bc488c-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var ColorContainer = styled.div.withConfig({
    componentId: "sc-e5bc488c-2"
})(_templateObject2());
export var Color = styled.div.withConfig({
    componentId: "sc-e5bc488c-3"
})(_templateObject3(), function(param) {
    var bColor = param.bColor;
    return bColor;
});
