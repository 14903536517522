export var organizeWinBackErrorPopup = function(cmsData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    return {
        popupTitle: (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.winbacknumber) === null || ref === void 0 ? void 0 : (ref1 = ref.expiredPopup) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        popupMessage: (cmsData === null || cmsData === void 0 ? void 0 : (ref2 = cmsData.winbacknumber) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.expiredPopup) === null || ref3 === void 0 ? void 0 : ref3.message) || "",
        popupCtaText: (cmsData === null || cmsData === void 0 ? void 0 : (ref4 = cmsData.winbacknumber) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.expiredPopup) === null || ref5 === void 0 ? void 0 : ref5.ctaText) || "",
        popupButtonText: (cmsData === null || cmsData === void 0 ? void 0 : (ref6 = cmsData.winbacknumber) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.expiredPopup) === null || ref7 === void 0 ? void 0 : ref7.buttonText) || "",
        popupButtonRedirect: (cmsData === null || cmsData === void 0 ? void 0 : (ref8 = cmsData.winbacknumber) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.expiredPopup) === null || ref9 === void 0 ? void 0 : ref9.buttonRedirect) || "",
        urlClickToCall: (cmsData === null || cmsData === void 0 ? void 0 : (ref10 = cmsData.winbacknumber) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.expiredPopup) === null || ref11 === void 0 ? void 0 : ref11.urlClickToCall) || ""
    };
};
