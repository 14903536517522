import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
var TaggingHubDevice = function(param) {
    var taggingOptions = param.taggingOptions, allDevices = param.allDevices, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var client = useQueryClient();
    var pageDevices = SoftManagerService(client).getSoftDevicesByDevices((allDevices === null || allDevices === void 0 ? void 0 : allDevices.filter(function(device) {
        var ref;
        return device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.isSellable;
    })) || []);
    useTracking({
        event: [
            "product_view"
        ],
        opts: _object_spread_props(_object_spread({}, taggingOptions), {
            product_category: pageDevices.product_category || ""
        }),
        pageProduct: pageDevices,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    });
    return null;
};
export default TaggingHubDevice;
