import { fonts, pxToLineHeight } from "@vfit/shared/themes";
export var organizeOtp = function(dataFromCms, msisdnOrEmail, contactMedium) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientRecognition) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.clientRecognition) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        codeResponseDescription: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.clientRecognition) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.codeResponse) === null || ref6 === void 0 ? void 0 : ref6.description) || "",
        instructionText: {
            text: msisdnOrEmail ? "".concat(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref7 = dataFromCms.personalinfo) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.clientRecognition) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.telephoneVerify) === null || ref9 === void 0 ? void 0 : ref9.replace("$number", '<b style="color:red">'.concat(contactMedium.field, "</b>"))) : "".concat(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref10 = dataFromCms.personalinfo) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.clientRecognition) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.emailVerify) === null || ref12 === void 0 ? void 0 : ref12.replace("$email", '\n<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">\n    <b style="color:red">'.concat((contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.field) || "", "</b>\n</div>\n"))),
            fontFamily: fonts.exbold,
            size: 20,
            lineHeight: pxToLineHeight(20, 26)
        },
        alerts: {
            error: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref13 = dataFromCms.personalinfo) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.clientRecognition) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.codeResponse) === null || ref15 === void 0 ? void 0 : ref15.alertWarn) || "",
            sentNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref16 = dataFromCms.personalinfo) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.clientRecognition) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.codeResponse) === null || ref18 === void 0 ? void 0 : ref18.alertSuccess) || ""
        },
        otpError: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref19 = dataFromCms.personalinfo) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.clientRecognition) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.codeResponse) === null || ref21 === void 0 ? void 0 : ref21.otpError) || "",
        detail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref22 = dataFromCms.personalinfo) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.clientRecognition) === null || ref23 === void 0 ? void 0 : ref23.codeMissingLabel) || "",
        links: {
            sendNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref24 = dataFromCms.personalinfo) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.clientRecognition) === null || ref25 === void 0 ? void 0 : ref25.codeNewLabel) || "",
            noAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref26 = dataFromCms.personalinfo) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.clientRecognition) === null || ref27 === void 0 ? void 0 : ref27.codeNotAccessLabel) || "",
            urlNoAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref28 = dataFromCms.personalinfo) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.clientRecognition) === null || ref29 === void 0 ? void 0 : ref29.contactNotAccessUrl) || ""
        }
    };
};
export var organizeOtpPortability = function(dataFromCms, msisdnOrEmail, contactMedium) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.title) || "",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.description) || "",
        codeResponseDescription: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.codeResponse) === null || ref === void 0 ? void 0 : ref.description) || "",
        instructionText: {
            text: msisdnOrEmail ? "".concat(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref1 = dataFromCms.telephoneVerify) === null || ref1 === void 0 ? void 0 : ref1.replace("$number", '<b style="color:red">'.concat(contactMedium.field, "</b>"))) : "".concat(dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.emailVerify) === null || ref2 === void 0 ? void 0 : ref2.replace("$email", '\n<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">\n    <b style="color:red">'.concat((contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.field) || "", "</b>\n</div>\n"))),
            fontFamily: fonts.exbold,
            size: 20,
            lineHeight: pxToLineHeight(20, 26)
        },
        alerts: {
            error: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.codeResponse) === null || ref3 === void 0 ? void 0 : ref3.alertWarn) || "",
            sentNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.codeResponse) === null || ref4 === void 0 ? void 0 : ref4.alertSuccess) || ""
        },
        otpError: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref5 = dataFromCms.codeResponse) === null || ref5 === void 0 ? void 0 : ref5.otpError) || "",
        detail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.codeMissingLabel) || "",
        links: {
            sendNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.codeNewLabel) || "",
            noAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.codeNotAccessLabel) || "",
            urlNoAccess: (dataFromCms === null || dataFromCms === void 0 ? void 0 : dataFromCms.contactNotAccessUrl) || ""
        }
    };
};
