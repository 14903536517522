import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  gap: 16px;\n  width: 287px;\n\n  @media (min-width: ",
        ") {\n    width: 366px;\n    gap: 12px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 406px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 2rem 0 1rem;\n  font-family: ",
        ";\n  font-weight: 400;\n  font-style: normal;\n  text-align: center;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    padding: 3.75rem 0 1rem;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0 auto;\n    font-family: ",
        ";\n    font-weight: 400;\n    font-style: normal;\n    text-align: center;\n    ",
        "\n    margin-bottom: 32px;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      margin-bottom: 40px;\n    }\n\n    p {\n      margin-block: 0;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 58px;\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 20px;\n  display: flex;\n  text-align: left;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  p{\n    margin: 0 auto ;\n    font-family: ",
        ";\n    font-weight: 400;\n    font-style: normal;\n    text-align: left;\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-925c1612-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-925c1612-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-925c1612-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var TextLoader = styled.div.withConfig({
    componentId: "sc-925c1612-3"
})(_templateObject3(), colors.$ffffff, breakpoints.tablet);
export var BottomLable = styled.div.withConfig({
    componentId: "sc-925c1612-4"
})(_templateObject4());
export var BottomText = styled.div.withConfig({
    componentId: "sc-925c1612-5"
})(_templateObject5(), fonts.regular, pxToCssFont(14, 18));
