import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { trackView } from "@vfit/shared/data-access";
import { retrieveProductTechnology } from "@vfit/consumer/data-access";
import { removeCurrency } from "@vfit/consumer/hooks";
var getTaggingActivationLanding = function(offer) {
    var pageType = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "activation";
    var trackingProduct = {
        cart_product_id: "".concat(offer === null || offer === void 0 ? void 0 : offer.id),
        cart_product_name: offer === null || offer === void 0 ? void 0 : offer.title,
        cart_product_category: "fixed",
        cart_product_price: (offer === null || offer === void 0 ? void 0 : offer.price) && removeCurrency(offer.price),
        cart_product_quantity: "1",
        cart_product_technology: retrieveProductTechnology() || "",
        cart_total: (offer === null || offer === void 0 ? void 0 : offer.price) && removeCurrency(offer.price)
    };
    var pageProductsInfo = {
        product_name: (offer === null || offer === void 0 ? void 0 : offer.title) || "",
        product_price: (offer === null || offer === void 0 ? void 0 : offer.price) ? removeCurrency(offer === null || offer === void 0 ? void 0 : offer.price) : "",
        product_id: "".concat((offer === null || offer === void 0 ? void 0 : offer.campaignId) || (offer === null || offer === void 0 ? void 0 : offer.id)),
        product_quantity: "1",
        product_category: "fixed"
    };
    var opts = {
        event_label: pageType,
        product_target_segment: "consumer",
        event_category: "sales",
        page_subsection: "activation",
        page_section: "fixed addons",
        page_type: pageType,
        journey_type: "journey",
        journey_name: "activation"
    };
    return {
        trackingProduct: trackingProduct,
        opts: opts,
        pageProductsInfo: pageProductsInfo
    };
};
var trackActivationLanding = function(offer, isSuccessActivation, isLoadingActivation, isErrorActivation) {
    var trackingObj = getTaggingActivationLanding(offer);
    var globalTracking = _object_spread_props(_object_spread({}, (trackingObj === null || trackingObj === void 0 ? void 0 : trackingObj.trackingProduct) && _object_spread({}, trackingObj.trackingProduct), (trackingObj === null || trackingObj === void 0 ? void 0 : trackingObj.pageProductsInfo) && _object_spread({}, trackingObj.pageProductsInfo), (trackingObj === null || trackingObj === void 0 ? void 0 : trackingObj.opts) && _object_spread({}, trackingObj.opts)), {
        product_quantity: "1",
        product_target_segment: "consumer"
    });
    if (isSuccessActivation) {
        trackView(_object_spread_props(_object_spread({}, globalTracking), {
            event_name: "view",
            event_label_track: "activation:typ",
            page_type: "activation typ"
        }));
    } else if (isErrorActivation) {
        trackView(_object_spread_props(_object_spread({}, globalTracking), {
            event_name: "page_error",
            event_label_track: "activation:error",
            page_type: "activation error"
        }));
    } else if (isLoadingActivation) {
        trackView(_object_spread_props(_object_spread({}, globalTracking), {
            event_name: "view",
            event_label_track: "activation:loading",
            page_type: "activation loading”"
        }));
    } else {
        trackView(_object_spread_props(_object_spread({}, globalTracking), {
            event_name: "view",
            event_label_track: "activation:confirm",
            page_type: "activation confirm"
        }));
    }
};
export { getTaggingActivationLanding, trackActivationLanding };
