import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { retrieveInTest } from "@vfit/consumer/data-access";
var checkIsInAbTest = function(product) {
    var isInTest = retrieveInTest() && (product === null || product === void 0 ? void 0 : product.enableAbTest);
    var abTestConfiguration = product.offerDetailABTest || {};
    return !isInTest ? product : _object_spread({}, product, abTestConfiguration);
};
var checkIsCVM = function(product) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    if (!product.isCvm) return product;
    if (!(product === null || product === void 0 ? void 0 : product.cvmConfig) || (product === null || product === void 0 ? void 0 : (ref = product.cvmConfig) === null || ref === void 0 ? void 0 : ref.length) === 0) return product;
    var urlParams = new URLSearchParams(window.location.search);
    var cvmConfiguration = urlParams === null || urlParams === void 0 ? void 0 : urlParams.get("cvm");
    if (!cvmConfiguration) return product;
    var foundedCvmConfig = product.cvmConfig.find(function(el) {
        return el.idCvm === cvmConfiguration;
    });
    if (!foundedCvmConfig) return product;
    return _object_spread_props(_object_spread({}, product), {
        price: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.price) || "",
        subprice: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.subprice) || "",
        originalPrice: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.originalPrice) || "",
        recurrence: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.recurrence) || "",
        title: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.titleHubCard) || "",
        description: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.priceDescription) || "",
        customDescriptionCVM: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.stickyOfferDescription) || "",
        action: _object_spread_props(_object_spread({}, foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.mainAction), {
            // eslint-disable-next-line no-unsafe-optional-chaining
            type: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref1 = foundedCvmConfig.mainAction) === null || ref1 === void 0 ? void 0 : ref1.type) ? +(foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref2 = foundedCvmConfig.mainAction) === null || ref2 === void 0 ? void 0 : ref2.type) : -1,
            callMeNow: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref3 = foundedCvmConfig.mainAction) === null || ref3 === void 0 ? void 0 : ref3.url) || ""
        }),
        pdpSecondAction: _object_spread_props(_object_spread({}, foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : foundedCvmConfig.secondAction), {
            // eslint-disable-next-line no-unsafe-optional-chaining
            type: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref4 = foundedCvmConfig.secondAction) === null || ref4 === void 0 ? void 0 : ref4.type) ? +(foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref5 = foundedCvmConfig.secondAction) === null || ref5 === void 0 ? void 0 : ref5.type) : -1,
            callMeNow: (foundedCvmConfig === null || foundedCvmConfig === void 0 ? void 0 : (ref6 = foundedCvmConfig.secondAction) === null || ref6 === void 0 ? void 0 : ref6.url) || ""
        })
    });
};
var checkProductConfigurations = function(product) {
    var productToReturn = checkIsInAbTest(product);
    productToReturn = checkIsCVM(productToReturn);
    return productToReturn;
};
export { checkProductConfigurations };
