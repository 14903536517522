import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n  color: #333;\n  margin-top: 0.5rem;\n  margin-bottom: 2rem;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  ",
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var ErrorMsg = styled.p.withConfig({
    componentId: "sc-94c01fa1-0"
})(_templateObject(), function(props) {
    return props.isManual ? "" : "display: none;";
}, colors.$e60000, pxToCssFont(14, 18), fonts.regular);
export var P = styled.p.withConfig({
    componentId: "sc-94c01fa1-1"
})(_templateObject1(), pxToCssFont(18, 24), fonts.light);
export var Title = styled.h3.withConfig({
    componentId: "sc-94c01fa1-2"
})(_templateObject2(), pxToCssFontSize(30), fonts.exbold, breakpoints.tablet, pxToCssFontSize(42), breakpoints.desktop, pxToCssFontSize(36));
