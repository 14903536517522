import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n  margin-bottom: 40px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n\n  @media (max-width: ",
        ") {\n    max-width: 80%;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      width: 300px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row-reverse;\n    justify-content: center;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  .rec {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 8px;\n\n    .rec {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-top: 8px;\n    margin-bottom: 8px;\n\n    p,\n    span {\n      ",
        "\n      color: ",
        ";\n      margin:0;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin: 0;\n      margin-top: 4px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  align-items: baseline;\n  justify-content: space-between;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  text-transform: uppercase;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: baseline;\n  margin: 0;\n  margin-bottom: 8px;\n\n  div {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    color: ",
        ";\n    margin: 0;\n\n    img {\n      width: 19px;\n    }\n  }\n\n  svg {\n    margin-right: 16px;\n    width: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var BottomContainer = styled.div.withConfig({
    componentId: "sc-962b3b86-0"
})(_templateObject());
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-962b3b86-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
export var SpecsDiv = styled.div.withConfig({
    componentId: "sc-962b3b86-2"
})(_templateObject2());
export var ParagraphContainer = styled.p.withConfig({
    componentId: "sc-962b3b86-3"
})(_templateObject3());
export var Title = styled(ParagraphContainer).withConfig({
    componentId: "sc-962b3b86-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.desktop, pxToCssFont(36, 45));
export var OfferPrice = styled(Title).withConfig({
    componentId: "sc-962b3b86-5"
})(_templateObject5(), pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(24, 30));
export var OfferDescription = styled.div.withConfig({
    componentId: "sc-962b3b86-6"
})(_templateObject6(), pxToCssFont(16, 22), colors.$262626, breakpoints.desktop);
export var ProductCardSpecContainer = styled.div.withConfig({
    componentId: "sc-962b3b86-7"
})(_templateObject7());
export var ProductCardSpecTitle = styled.p.withConfig({
    componentId: "sc-962b3b86-8"
})(_templateObject8(), fonts.regular, pxToCssFontSize(18), colors.$262626);
export var ProductCardSpecItem = styled.div.withConfig({
    componentId: "sc-962b3b86-9"
})(_templateObject9(), fonts.regular, pxToCssFont(20, 30), colors.$262626, breakpoints.tablet, breakpoints.desktop);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-962b3b86-10"
})(_templateObject10(), pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-962b3b86-11"
})(_templateObject11());
