import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useTracking } from "@vfit/shared/providers";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useQueryClient } from "react-query";
import { setTrackLink, setTrackView } from "@vfit/shared/data-access";
var TaggingBlank = function(param) {
    var taggingOptions = param.taggingOptions, productsAll = param.productsAll, isDisabledInitialTrack = param.isDisabledInitialTrack;
    var client = useQueryClient();
    var pageProduct = SoftManagerService(client).getSoftProductsByProducts((productsAll === null || productsAll === void 0 ? void 0 : productsAll.filter(function(el) {
        return el.isShowedInHub;
    })) || []);
    var trackView = useTracking({
        event: [
            (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.event) || "view"
        ],
        event_label: (taggingOptions === null || taggingOptions === void 0 ? void 0 : taggingOptions.eventLabel) || "",
        opts: _object_spread_props(_object_spread({}, taggingOptions), {
            product_category: (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_category) || ""
        }),
        pageProduct: pageProduct,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
        disableInitialTrack: isDisabledInitialTrack || false
    }).trackView;
    setTrackView(trackView);
    setTrackLink(trackView);
    return null;
};
export default TaggingBlank;
