import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  flex-grow: 1;\n\n  @media (min-width: ",
        ") {\n    width: 50%;\n    align-items: flex-start;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  line-height: ",
        ";\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 500;\n  font-size: ",
        ";\n\n  @media (min-width: ",
        ") {\n    line-height: ",
        ";\n    font-size: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  line-height: ",
        ";\n  font-family: ",
        ";\n  font-size: ",
        ";\n  margin: 0;\n  text-align: center;\n\n  p {\n    margin: 0;\n    font-size: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n    font-size: ",
        ";\n    line-height: 1.55;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-d9991960-0"
})(_templateObject(), pxToRem(8), breakpoints.desktop);
export var Title = styled.h2.withConfig({
    componentId: "sc-d9991960-1"
})(_templateObject1(), pxToRem(32.2), fonts.exbold, pxToRem(28), breakpoints.desktop, pxToRem(48.6), pxToRem(36));
export var Description = styled.div.withConfig({
    componentId: "sc-d9991960-2"
})(_templateObject2(), pxToRem(28), fonts.light, pxToRem(20), pxToRem(20), breakpoints.desktop, pxToRem(22));
