import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { check, getForeignCountries, getItalianMunicipalities, isValidDate, nameCode, surnameCode } from "@vfit/shared/data-access";
var normalizeCF = function(cf) {
    return cf.toUpperCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
var isValidCFLength = function(fiscalCode) {
    return typeof fiscalCode === "string" && fiscalCode.length === 16;
};
var hasCFValidDate = function(cf) {
    var yearPart = normalizeCF(cf).substring(6, 8);
    var monthCode = cf.charAt(8).toUpperCase();
    var dayPart = parseInt(cf.substring(9, 11), 10);
    var months = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        H: 6,
        L: 7,
        M: 8,
        P: 9,
        R: 10,
        S: 11,
        T: 12
    };
    var year = parseInt(yearPart, 10);
    var fullYear = year < 40 ? 2000 + year : 1900 + year;
    var month = months[monthCode];
    if (!month) return false;
    if (dayPart > 40) dayPart -= 40;
    return isValidDate(dayPart, month, fullYear);
};
var hasCFValidPlace = function(cf) {
    return _to_consumable_array(getItalianMunicipalities()).concat(_to_consumable_array(getForeignCountries())).some(function(m) {
        return m.code === normalizeCF(cf).substring(11, 15);
    });
};
var matchNameCF = function(name, lastName, cf) {
    return normalizeCF("".concat(surnameCode(lastName).substring(0, 3)).concat(nameCode(name).substring(0, 3))) === normalizeCF(cf).substring(0, 6);
};
var isValidCF = function(firstName, lastName, fiscalCode) {
    return matchNameCF(firstName, lastName, fiscalCode) && check(fiscalCode) && hasCFValidDate(fiscalCode) && hasCFValidPlace(fiscalCode);
};
export { isValidCF, isValidCFLength, normalizeCF };
