export var organizeAddNumber = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.editNumber) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        subtitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.clientLogged) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.editNumber) === null || ref5 === void 0 ? void 0 : ref5.description) || "",
        buttonText: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientLogged) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.editNumber) === null || ref8 === void 0 ? void 0 : ref8.buttonLabel) || "",
        numbers: [
            {
                title: "******1111",
                value: "1111111111"
            },
            {
                title: "******2222",
                value: "2222222222"
            },
            {
                title: "******3333",
                value: "3333333333"
            }, 
        ],
        selIndexMok: 2,
        ctaLable: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfo) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.clientLogged) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.editNumber) === null || ref11 === void 0 ? void 0 : ref11.addNewNumberLabel) || ""
    };
};
