import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0 16px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 157px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 132vw;\n  overflow: hidden;\n  position: relative;\n  background: #f4f4f4;\n\n  @media (min-width: ",
        ") {\n    height: 650px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: 0 46px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: center;\n\n    button {\n      width: 150px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  transform: rotate(30deg);\n  position: absolute;\n  width: 100%;\n  height: 188vw;\n  bottom: 28vw;\n  right: -39vw;\n  overflow: hidden;\n  border-radius: 50vw;\n\n  img {\n    position: absolute;\n    width: 132vw;\n    max-width: none !important;\n    left: -17vw;\n    bottom: 0;\n    transform: rotate(-30deg);\n  }\n\n  @media (min-width: ",
        ") {\n    height: 1300px;\n    width: 550px;\n    border-radius: 381px;\n    bottom: 40px;\n    right: -80px;\n\n    img {\n      width: 141%;\n      max-width: none !important;\n      left: -30px;\n      top: 680px;\n      object-fit: cover;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  margin-bottom: 30px;\n  gap: 50px;\n  padding: 0 20px;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    justify-content: center;\n    padding: 0 62px;\n    gap: 30px;\n\n    .container {\n      width: 50%;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 50%;\n  gap: 16px;\n  font-size: 18px;\n  flex-direction: column;\n  margin-bottom: 32px;\n  padding-right: 13px;\n  @media (min-width: ",
        ") {\n    justify-content: center;\n  }\n\n  div {\n    font-size: 18px;\n    margin: 0 0 0 4px;\n    border: none;\n    font-weight: normal;\n    font-family: ",
        ";\n    padding: 0;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
var Container = styled.div.withConfig({
    componentId: "sc-eeec0277-0"
})(_templateObject(), fonts.regular);
var FormContainer = styled.div.withConfig({
    componentId: "sc-eeec0277-1"
})(_templateObject1(), breakpoints.desktop);
var ImageDiv = styled.div.withConfig({
    componentId: "sc-eeec0277-2"
})(_templateObject2(), breakpoints.desktop);
var ButtonContainer = styled.div.withConfig({
    componentId: "sc-eeec0277-3"
})(_templateObject3(), breakpoints.tablet);
var ImageSection = styled.article.withConfig({
    componentId: "sc-eeec0277-4"
})(_templateObject4(), breakpoints.desktop);
var H1 = styled.h1.withConfig({
    componentId: "sc-eeec0277-5"
})(_templateObject5(), fonts.regular, pxToCssFont(30, 30));
var InputContainer = styled.div.withConfig({
    componentId: "sc-eeec0277-6"
})(_templateObject6(), breakpoints.desktop);
var RadioListContainer = styled.div.withConfig({
    componentId: "sc-eeec0277-7"
})(_templateObject7(), breakpoints.tablet, fonts.regular);
export { Container, FormContainer, InputContainer, ButtonContainer, H1, ImageDiv, ImageSection, RadioListContainer };
