import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  justify-items: center;\n  width: 100%;\n  height: auto;\n  background: rgba(221, 230, 235, 100%);\n  padding: 24px;\n  gap: 8px;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    padding: 0;\n    height: 80px !important;\n    gap: 16px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 48px;\n  height: 48px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 28px;\n\n  @media (min-width: ",
        ") {\n    font-size: 22px;\n    line-height: 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  color: rgba(0, 0, 0, 100%);\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts } from "@vfit/shared/themes";
export var BannerContainer = styled.div.withConfig({
    componentId: "sc-a2c99dab-0"
})(_templateObject(), breakpoints.tablet);
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-a2c99dab-1"
})(_templateObject1());
export var Text = styled.div.withConfig({
    componentId: "sc-a2c99dab-2"
})(_templateObject2(), fonts.regular, breakpoints.desktop);
export var Link = styled.a.withConfig({
    componentId: "sc-a2c99dab-3"
})(_templateObject3());
