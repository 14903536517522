import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  width: fit-content;\n  margin: 32px 20px 0;\n\n  @media (min-width: ",
        ") {\n    margin: 60px 40px;\n    width: auto;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 60px 55px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    font-weight: 400;\n    text-align: center;\n    ",
        "\n    margin-bottom: 30px;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n      margin-bottom: 35px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin-bottom: 40px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-top: 20px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n  border-radius: 5px;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 162px;\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-7cf730a8-0"
})(_templateObject(), colors.$262626, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-7cf730a8-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-7cf730a8-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var LinkWrapper = styled.div.withConfig({
    componentId: "sc-7cf730a8-3"
})(_templateObject3());
export var TextLoader = styled.div.withConfig({
    componentId: "sc-7cf730a8-4"
})(_templateObject4(), colors.$ffffff, breakpoints.tablet);
