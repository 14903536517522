import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { APP_PLACEHOLDERS, ENRG_QSTRING } from "@vfit/consumer/data-access";
import { checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { IActionType } from "@vfit/shared/models";
var showError = function() {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: "Ops!",
        message: "Si \xe8 verificato un errore. Riprova in seguito",
        actionText: "Chiudi",
        actionEvent: function() {
            return window.location.reload();
        },
        onClose: function() {
            return window.location.reload();
        }
    });
};
var getAppSessionItems = function() {
    var ref, ref1;
    var appSession = checkWindow() ? getFromLocalStorageByKey("appSession") : null;
    if (!(appSession === null || appSession === void 0 ? void 0 : appSession.items) || (appSession === null || appSession === void 0 ? void 0 : appSession.items.length) === 0) {
        showError();
        return "";
    }
    return (ref1 = appSession === null || appSession === void 0 ? void 0 : (ref = appSession.items) === null || ref === void 0 ? void 0 : ref.map(function(e) {
        return e.value || "";
    })) === null || ref1 === void 0 ? void 0 : ref1.join(",");
};
/**
 * Method to organize the hub app page
 * @param queryClient
 */ var organizeHubAppPage = function(queryClient) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    var cmsPage = queryClient.getQueryData("getHubApp");
    var ref11 = cmsPage || {}, pagesparams = ref11.pagesparams, widgets = ref11.widgets, tagging = ref11.tagging, seo = ref11.seo, fullbanner = ref11.fullbanner;
    return {
        params: {
            key: pagesparams === null || pagesparams === void 0 ? void 0 : pagesparams.key,
            haveHeader: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref = pagesparams.visibility) === null || ref === void 0 ? void 0 : ref.haveHeader) === "true",
            isHeaderSmall: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref1 = pagesparams.visibility) === null || ref1 === void 0 ? void 0 : ref1.isHeaderSmall) === "true",
            haveFooter: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref2 = pagesparams.visibility) === null || ref2 === void 0 ? void 0 : ref2.haveFooter) === "true",
            isShow: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref3 = pagesparams.visibility) === null || ref3 === void 0 ? void 0 : ref3.showPages) === "true",
            isDisabledInitialTrack: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref4 = pagesparams.visibility) === null || ref4 === void 0 ? void 0 : ref4.isDisabledInitialTrack) === "true",
            isProtected: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref5 = pagesparams.visibility) === null || ref5 === void 0 ? void 0 : ref5.isProtected) === "true",
            protectedPass: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref6 = pagesparams.visibility) === null || ref6 === void 0 ? void 0 : ref6.protectedPass) || "",
            path: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref7 = pagesparams.urlBuilder) === null || ref7 === void 0 ? void 0 : ref7.map(function(urlBuilder) {
                var ref, ref1;
                return ((ref1 = (ref = urlBuilder.page) === null || ref === void 0 ? void 0 : ref.trim()) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) || "";
            })) || [
                ""
            ],
            elements: _object_spread({}, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref8 = pagesparams.apiPath) === null || ref8 === void 0 ? void 0 : ref8.apiProductsList) && {
                apiProductsList: pagesparams.apiPath.apiProductsList
            }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref9 = pagesparams.apiPath) === null || ref9 === void 0 ? void 0 : ref9.apiOfferList) && {
                apiOfferList: pagesparams.apiPath.apiOfferList
            }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref10 = pagesparams.apiPath) === null || ref10 === void 0 ? void 0 : ref10.apiDeviceList) && {
                apiDeviceList: pagesparams.apiPath.apiDeviceList
            }, widgets && (widgets === null || widgets === void 0 ? void 0 : widgets.sliders) && {
                widgets: widgets
            }, seo && {
                seo: seo
            }, fullbanner && {
                fullbanner: fullbanner
            }, tagging && {
                taggingOptions: {
                    event_category: (tagging === null || tagging === void 0 ? void 0 : tagging.eventCategory) || "",
                    page_section: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSection) || "",
                    page_subsection: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSubsection) || "",
                    page_type: (tagging === null || tagging === void 0 ? void 0 : tagging.pageType) || "",
                    event: (tagging === null || tagging === void 0 ? void 0 : tagging.event) || "",
                    eventLabel: (tagging === null || tagging === void 0 ? void 0 : tagging.eventLabel) || "",
                    journey_type: (tagging === null || tagging === void 0 ? void 0 : tagging.journeyType) || "",
                    product_target_segment: (tagging === null || tagging === void 0 ? void 0 : tagging.productTargetSegment) || "",
                    product_category: (tagging === null || tagging === void 0 ? void 0 : tagging.productCategory) || ""
                }
            })
        }
    };
};
/**
 * Method to check if addon is visible in all section
 * @param sections
 * @param boId
 */ var isVisibleAddon = function(sections, boId) {
    var isVisible = false;
    sections === null || sections === void 0 ? void 0 : sections.forEach(function(s) {
        var productsSection = (s === null || s === void 0 ? void 0 : s.products) || [];
        productsSection === null || productsSection === void 0 ? void 0 : productsSection.forEach(function(p) {
            var ref, ref1;
            var billingOffer = (p === null || p === void 0 ? void 0 : (ref = p.campaignDetail) === null || ref === void 0 ? void 0 : (ref1 = ref.components) === null || ref1 === void 0 ? void 0 : ref1.billingOffer) || [];
            var billingOfferIds = billingOffer === null || billingOffer === void 0 ? void 0 : billingOffer.map(function(b) {
                var ref;
                return ((ref = b.id) === null || ref === void 0 ? void 0 : ref.toString()) || "";
            });
            if (!isVisible && boId && (billingOfferIds === null || billingOfferIds === void 0 ? void 0 : billingOfferIds.includes(boId))) {
                isVisible = true;
            }
        });
    });
    return isVisible;
};
var getSliders = function(sliders, activableData) {
    var querystringParams = checkWindow() && window.location.search || {};
    var urlParams = new URLSearchParams(querystringParams);
    var allSections = (activableData === null || activableData === void 0 ? void 0 : activableData.sections) || [];
    return sliders === null || sliders === void 0 ? void 0 : sliders.map(function(slider) {
        var ref;
        var elements = slider.elements;
        if (!elements || (elements === null || elements === void 0 ? void 0 : elements.length) === 0) return slider;
        return _object_spread_props(_object_spread({}, slider), {
            elements: (ref = elements === null || elements === void 0 ? void 0 : elements.filter(function(e) {
                if (e.id) {
                    var ref;
                    console.log(e.id);
                    return isVisibleAddon(allSections, e === null || e === void 0 ? void 0 : (ref = e.id) === null || ref === void 0 ? void 0 : ref.toString());
                }
                return e.category === "all";
            })) === null || ref === void 0 ? void 0 : ref.map(function(e) {
                var ref, ref1, ref2, ref3, ref4;
                return _object_spread_props(_object_spread({}, e), {
                    category: e.category === "all" ? "" : (e === null || e === void 0 ? void 0 : e.category) || "",
                    action: _object_spread_props(_object_spread({}, e.action, (e === null || e === void 0 ? void 0 : e.id) && isVisibleAddon(allSections, e === null || e === void 0 ? void 0 : (ref = e.id) === null || ref === void 0 ? void 0 : ref.toString()) && {
                        type: IActionType.HUB_CATALOG_ACT
                    }), {
                        url: (ref4 = (ref3 = e === null || e === void 0 ? void 0 : (ref1 = e.action) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.url) === null || ref2 === void 0 ? void 0 : ref2.replace(APP_PLACEHOLDERS.ACCOUNT_APP, (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get(ENRG_QSTRING.ACCOUNT)) || "")) === null || ref3 === void 0 ? void 0 : ref3.replace(APP_PLACEHOLDERS.CHECKSUM_APP, (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get(ENRG_QSTRING.CHECKSUM)) || "")) === null || ref4 === void 0 ? void 0 : ref4.replace(APP_PLACEHOLDERS.MSISDN_APP, (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get(ENRG_QSTRING.MSISDN)) || "")
                    })
                });
            })
        });
    });
};
export { getAppSessionItems, showError, getSliders, organizeHubAppPage };
