import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import { organizeProductCharacteristicsTable } from "./productCharacteristicsTable.organize";
export var useProductCharacteristicsTable = function(widgetsCharacteristics) {
    var _ref = organizeProductCharacteristicsTable(widgetsCharacteristics), table = _ref.table, rest = _object_without_properties(_ref, [
        "table"
    ]);
    return {
        data: {
            table: table,
            texts: rest
        }
    };
};
