import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n  padding: 0;\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 0;\n    width: 100%;\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wrapper = styled.section.withConfig({
    componentId: "sc-a7e4193e-0"
})(_templateObject(), pxToRem(32), pxToRem(20, 40), breakpoints.tablet, pxToRem(20, 60), breakpoints.desktop, pxToRem(80));
