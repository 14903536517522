export var organizeEditNumber = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.editNumber) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        titleNew: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.clientLogged) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.newNumber) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
        subtitle: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientLogged) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.newNumber) === null || ref8 === void 0 ? void 0 : ref8.description) || "",
        buttonText: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfo) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.clientLogged) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.newNumber) === null || ref11 === void 0 ? void 0 : ref11.buttonLabel) || "",
        lable: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref12 = dataFromCms.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.telephone) || ""
    };
};
