import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n\n  @media (min-width: ",
        ") {\n    // margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    text-align: center;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 33%;\n\n    div {\n      text-align: left;\n      margin-left: 81px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin-left: 81px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var ProductSolutionsContainer = styled.div.withConfig({
    componentId: "sc-8858d840-0"
})(_templateObject(), breakpoints.desktop, breakpoints.bigDesktop);
export var ProductSolutionsText = styled.div.withConfig({
    componentId: "sc-8858d840-1"
})(_templateObject1(), breakpoints.desktop);
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-8858d840-2"
})(_templateObject2(), fonts.regular, colors.$7e7e7e, breakpoints.desktop);
