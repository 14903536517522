import { useQueryClient } from "react-query";
import { getCmsLabel } from "../../utils/cms";
export var useOrganizeLoginModal = function() {
    var queryClient = useQueryClient();
    var LOGIN_DATA = {
        anchorText: getCmsLabel(queryClient, "login", "anchorText") || "Hai dimenticato le tue credenziali?",
        buttonText: getCmsLabel(queryClient, "login", "buttonText") || "ACCEDI",
        urlToRedirect: getCmsLabel(queryClient, "login", "urlToRedirect") || "https://www.vodafone.it/area-utente/appmanager/fai-da-te/Common?_nfpb=true&_pageLabel=P12800731511384768997354",
        urlToRegister: getCmsLabel(queryClient, "login", "urlToRegister") || "https://www.vodafone.it/area-utente/appmanager/fai-da-te/Common?_nfpb=true&_pageLabel=P5000326721379247293427",
        modalTitle: getCmsLabel(queryClient, "login", "modalTitle") || "Ops",
        errorPassword: getCmsLabel(queryClient, "login", "errorPassword") || "Password errata",
        modalDescription: getCmsLabel(queryClient, "login", "modalDescription") || "Email e/o Password errate",
        tabs: [
            getCmsLabel(queryClient, "login", "buttonAccess") || "Accedi",
            getCmsLabel(queryClient, "login", "buttonRegister") || "Registrati", 
        ]
    };
    var OTP_DATA = {
        title: getCmsLabel(queryClient, "otp", "title") || "Verifica identit\xe0",
        description: getCmsLabel(queryClient, "otp", "description") || "Per confermare la tua identit\xe0, inserisci il codice a 6 cifre che abbiamo inviato al tuo numero di telefono.",
        inputLabel: getCmsLabel(queryClient, "otp", "inputLabel") || "Inserisci il codice ricevuto",
        newCodeLabel: getCmsLabel(queryClient, "otp", "newCodeLabel") || "Non hai ricevuto il codice?",
        newCodeAnchor: getCmsLabel(queryClient, "otp", "newCodeAnchor") || "Richiedi nuovo codice",
        newCodeDisclaimer: getCmsLabel(queryClient, "otp", "newCodeDisclaimer") || "Ti abbiamo inviato un nuovo codice, ricontrolla tra qualche istante",
        errorDisclaimer: getCmsLabel(queryClient, "otp", "errorDisclaimer") || "Il codice inserito non \xe8 corretto, prova ad inserirlo nuovamente"
    };
    return {
        login: LOGIN_DATA,
        otp: OTP_DATA
    };
};
