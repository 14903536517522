import * as yup from "yup";
export var nameSurnameFiscalCode = yup.object({
    fiscalCode: yup.string().min(1, "Campo obbligatorio").max(16, "Inserire un codice fiscale valido").test("fiscalCode", "Inserire un codice fiscale valido", function(value) {
        return !!((value === null || value === void 0 ? void 0 : value.length) === 16 && (value === null || value === void 0 ? void 0 : value.match(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/)));
    }),
    firstName: yup.string().matches(/^([A-zÀ-ú]{1,30})((((-||\\s||.||'||")[A-zÀ-ú]))*(\s)*?)$/, "Inserire un nome valido").test("rangeLen", "Campo obbligatorio", function(val) {
        return !(val === undefined || val.trim().length < 2 || val.trim().length > 30);
    }),
    lastName: yup.string().matches(/^([A-zÀ-ú]{1,30})((((-||\\s||.||'||")[A-zÀ-ú]))*(\s)*)$/, "Inserire un cognome valido").test("rangeLen", "Campo obbligatorio", function(val) {
        return !(val === undefined || val.trim().length < 2 || val.trim().length > 30);
    })
});
// regex
yup.addMethod(yup.string, "expiryDate", function placehoder(errorMessage) {
    return this.test("test-expity-date", errorMessage, function placeholder2(value) {
        var ref = this, path = ref.path, createError = ref.createError;
        return value && value.length === 16 || createError({
            path: path,
            message: errorMessage
        });
    });
});
