export var evaluateSizes = function(models, characteristics) {
    var ref, ref1;
    var allSizes = (characteristics === null || characteristics === void 0 ? void 0 : (ref = characteristics.sizes) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.map(function(el) {
        return el;
    })) || [];
    return allSizes === null || allSizes === void 0 ? void 0 : allSizes.filter(function(el) {
        return models === null || models === void 0 ? void 0 : models.find(function(model) {
            return model.idSize === el.id.toString();
        });
    });
};
export var evaluateColors = function(models) {
    var displayNameSet = new Set();
    var colors = [];
    models.forEach(function(model) {
        if (!displayNameSet.has(model.displayNameColor)) {
            displayNameSet.add(model.displayNameColor);
            colors.push({
                id: model.idColor,
                displayName: model.displayNameColor
            });
        }
    });
    return colors;
};
export var isAvailableSize = function(selectedSku, models, sizeId) {
    var skuFiltered = models.filter(function(sku) {
        return sku.displayNameColor === selectedSku.displayNameColor;
    });
    return skuFiltered.some(function(sku) {
        return sku.idSize === sizeId;
    });
};
export var isAvailableColor = function(selectedSku, models, colorName) {
    var skuFiltered = models.filter(function(sku) {
        return sku.idSize === selectedSku.idSize;
    });
    return skuFiltered.some(function(sku) {
        return sku.displayNameColor === colorName;
    });
};
export var selectSkuBySize = function(models, sizeId, selectedSku) {
    var modelsByColor = models.filter(function(model) {
        return model.displayNameColor === selectedSku.displayNameColor;
    });
    var foundSku = modelsByColor.find(function(model) {
        return model.idSize === sizeId;
    });
    if (foundSku) {
        return foundSku;
    }
    return models.find(function(model) {
        return model.idSize === sizeId;
    });
};
export var showBorderColor = function(inputColor) {
    var showBorder = false;
    var rgbColor = inputColor.startsWith("#") ? hexToRgbConvert(inputColor) : inputColor;
    var match = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    var r = parseInt((match === null || match === void 0 ? void 0 : match[1]) || "", 10);
    var g = parseInt((match === null || match === void 0 ? void 0 : match[2]) || "", 10);
    var b = parseInt((match === null || match === void 0 ? void 0 : match[3]) || "", 10);
    if (r > 245 && g > 245 && b > 245) {
        showBorder = true;
    }
    return showBorder;
};
var hexToRgbConvert = function(hexColor) {
    var r = parseInt(hexColor.slice(1, 3), 16);
    var g = parseInt(hexColor.slice(3, 5), 16);
    var b = parseInt(hexColor.slice(5, 7), 16);
    return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
};
