import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { IGetItemType } from "@vfit/consumer/data-access";
import { AppMobile, getButtonActionByActionType } from "@vfit/shared/data-access";
import { IActionType } from "@vfit/shared/models";
import { ICMSWidgetType } from "../widgets/widgets.models";
import { productsProvider, simpleComponentModalManager, simpleTextModalManager } from "../providers";
import { ICMSPlaceholders } from "../utils/placeholders";
import { ISimpleComponentModalType } from "../providers/simpleComponentModalManager/interfaces";
import { changeTextByFlag } from "../widgets/SearchingOffersEu/searchingOffersEu.utils";
import { getCmsLabel } from "../utils/cms";
var NOT_IS_LOGGED_ACTIVATION_LABEL = "Accedi per attivare";
var NOT_IS_LOGGED_ACTIVATION_LABEL_ENRICHED = "Accedi con OTP per attivare";
var convertSpecialChars = function(str) {
    if (!str) return "";
    var mapChars = {
        "&amp;": "&",
        "&#61;": "=",
        "&#34;": '"',
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#039;": "'",
        "&copy;": "\xa9",
        "&reg;": "\xae",
        "&euro;": "€",
        "&pound;": "\xa3",
        "&yen;": "\xa5",
        "&cent;": "\xa2"
    };
    return str.replace(/&amp;|&lt;|&#61;|&#34;|&gt;|&quot;|&#039;|&copy;|&reg;|&euro;|&pound;|&yen;|&cent;/g, function(m) {
        return mapChars[m];
    });
};
/**
 * Get the consistency addons
 * @param consistency
 */ var getConsistencyAddons = function(consistency) {
    var ref, ref1, ref2;
    if (!consistency) return [];
    var addonOffers = consistency === null || consistency === void 0 ? void 0 : (ref = consistency.simItems) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.sections) === null || ref2 === void 0 ? void 0 : ref2.find(function(el) {
        var ref;
        return ((ref = el.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) == IGetItemType.ADDON;
    });
    return (addonOffers === null || addonOffers === void 0 ? void 0 : addonOffers.products) || [];
};
/**
 * Method to organize anchor widget with custom actions
 * @param widget
 * @param allWidgets
 * @param nation
 */ var organizeWidgetAnchor = function(widget, allWidgets, nation) {
    var ref;
    return _object_spread_props(_object_spread({}, widget), {
        elements: widget === null || widget === void 0 ? void 0 : (ref = widget["elements"]) === null || ref === void 0 ? void 0 : ref.map(function(element) {
            var ref, ref1;
            return _object_spread_props(_object_spread({}, element), {
                description: changeTextByFlag(element.description || "", (nation === null || nation === void 0 ? void 0 : nation.slug) || "", "$NAZIONE$"),
                action: _object_spread({}, element.action, (element === null || element === void 0 ? void 0 : (ref = element.action) === null || ref === void 0 ? void 0 : ref.type) == IActionType.LOCAL_URL && (element === null || element === void 0 ? void 0 : (ref1 = element.action) === null || ref1 === void 0 ? void 0 : ref1.localUrl) === ICMSPlaceholders.OPERATOR_REC_TABLE_MODAL && {
                    onClick: function() {
                        simpleComponentModalManager.handleSimpleComponentModal({
                            title: "Tariffe a consumo in ".concat(nation === null || nation === void 0 ? void 0 : nation.name, " per SIM ricaricabili"),
                            type: ISimpleComponentModalType.OPERATOR_REC_TABLE_MODAL,
                            data: allWidgets.find(function(el) {
                                return el["type"] === ICMSWidgetType.OFFERS_WORLD;
                            })
                        });
                    }
                })
            });
        })
    });
};
/**
 * TMP Check for smart passport. Add flag in cms
 * @param offer
 */ var isSmartPassport = function(offer) {
    var ref, ref1, ref2, ref3;
    return ((ref1 = offer === null || offer === void 0 ? void 0 : (ref = offer.overrideCategory) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("smart passport")) || ((ref3 = offer === null || offer === void 0 ? void 0 : (ref2 = offer.overrideOfferName) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) === null || ref3 === void 0 ? void 0 : ref3.includes("smart passport"));
};
/**
 * TMP Check for travel mondo. Add flag in cms
 * @param offers
 */ var isTravelMondoProduct = function(offers) {
    return offers === null || offers === void 0 ? void 0 : offers.some(function(element) {
        var ref, ref1;
        return (ref1 = element === null || element === void 0 ? void 0 : (ref = element.overrideCategory) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("travel mondo");
    });
};
/**
 * Check if cms product is in consistency (already active)
 * @param consistencyAddons
 * @param dxlId
 */ var isInConsistency = function(consistencyAddons, dxlId) {
    if (!dxlId) return false;
    var allIdsInConsistency = consistencyAddons === null || consistencyAddons === void 0 ? void 0 : consistencyAddons.map(function(el) {
        return el.id;
    });
    return allIdsInConsistency.includes(dxlId);
};
/**
 * Is Extra UE Nation
 * @param nation
 */ var isNationExtraEu = function(nation) {
    return !(nation === null || nation === void 0 ? void 0 : nation.isEU) && (nation === null || nation === void 0 ? void 0 : nation.name);
};
/**
 * Method for change multiple widget keys.Is used for replace for this moment "APP_QR" widget
 * @param widgets
 * @param keysToChange
 */ var changeMultipleWidgetType = function(widgets, keysToChange) {
    var aemData = {};
    Object.keys(widgets).forEach(function(widgetKey) {
        var foundedKeyIndex = keysToChange.findIndex(function(keyEl) {
            return widgetKey.includes(keyEl.key);
        });
        if (foundedKeyIndex !== -1) {
            aemData[widgetKey] = _object_spread_props(_object_spread({}, aemData[widgetKey] = widgets[widgetKey]), {
                type: keysToChange[foundedKeyIndex].replaceKey
            });
        } else {
            aemData[widgetKey] = widgets[widgetKey];
        }
    });
    return aemData;
};
/**
 * Method to organize hub widget
 */ var organizeProducts = function(queryClient, widget, isLogged, isEnriched, isLegacy, nation, consumerOffersCSV, activableProducts, alreadyActivatedOffers, consistency, onActiveProduct) {
    var ref, ref1, ref2, ref3;
    var consistencyAddons = getConsistencyAddons(consistency);
    var isApp = AppMobile.checkIsApp();
    var countryElementInCsv = consumerOffersCSV.find(function(offer) {
        return (offer === null || offer === void 0 ? void 0 : offer.slugCountry) === (nation === null || nation === void 0 ? void 0 : nation.slug);
    });
    var smartPassportIds = (ref = consistencyAddons === null || consistencyAddons === void 0 ? void 0 : consistencyAddons.filter(function(c) {
        var ref, ref1;
        return (ref1 = c === null || c === void 0 ? void 0 : (ref = c.name) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === null || ref1 === void 0 ? void 0 : ref1.includes("smart passport");
    })) === null || ref === void 0 ? void 0 : ref.map(function(c) {
        return c.id;
    });
    var activableIds = (ref1 = activableProducts === null || activableProducts === void 0 ? void 0 : activableProducts.map(function(p) {
        var ref;
        return (p === null || p === void 0 ? void 0 : (ref = p.id) === null || ref === void 0 ? void 0 : ref.productOfferingId) || "";
    })) === null || ref1 === void 0 ? void 0 : ref1.filter(function(id) {
        var foundAddon = consistencyAddons === null || consistencyAddons === void 0 ? void 0 : consistencyAddons.find(function(el) {
            return el.id == id;
        });
        return !foundAddon;
    });
    var dxlCatalogIds = _to_consumable_array(activableIds).concat(_to_consumable_array(smartPassportIds));
    var getProductInCsv = function(cmsId) {
        var ref;
        return countryElementInCsv === null || countryElementInCsv === void 0 ? void 0 : (ref = countryElementInCsv.elements) === null || ref === void 0 ? void 0 : ref.find(function(filterEl) {
            var ref;
            return (filterEl === null || filterEl === void 0 ? void 0 : (ref = filterEl.offerId) === null || ref === void 0 ? void 0 : ref.toString()) === cmsId;
        });
    };
    var getProductActions = function(actions, dxlId) {
        var ref;
        // if is in consistency remove action (Es. Smart passport)
        // or if user already activated offer
        if (isInConsistency(consistencyAddons, dxlId) || alreadyActivatedOffers.includes(dxlId)) return [];
        return (ref = actions === null || actions === void 0 ? void 0 : actions.filter(function(action) {
            if (isApp) return (action === null || action === void 0 ? void 0 : action.isForApp) === "true";
            return (action === null || action === void 0 ? void 0 : action.isForApp) !== "true";
        })) === null || ref === void 0 ? void 0 : ref.map(function(action) {
            var ref;
            var isActivationButton = action.type == IActionType.LOCAL_URL && (action === null || action === void 0 ? void 0 : (ref = action.url) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === ICMSPlaceholders.ACTIVATE;
            if (!isActivationButton) {
                return _object_spread_props(_object_spread({}, action), {
                    onClick: function() {
                        getButtonActionByActionType(action);
                    }
                });
            }
            var buttonLabel = (action === null || action === void 0 ? void 0 : action.title) || "";
            if (!isLogged && !isEnriched) buttonLabel = NOT_IS_LOGGED_ACTIVATION_LABEL;
            if (!isLogged && isEnriched) buttonLabel = NOT_IS_LOGGED_ACTIVATION_LABEL_ENRICHED;
            return _object_spread_props(_object_spread({}, action), {
                title: buttonLabel,
                onClick: function() {
                    return onActiveProduct === null || onActiveProduct === void 0 ? void 0 : onActiveProduct(dxlId);
                }
            });
        });
    };
    var titleHub = convertSpecialChars((widget === null || widget === void 0 ? void 0 : widget["titleHtml"]) || "");
    if (isLogged && (nation === null || nation === void 0 ? void 0 : nation.name)) {
        if (isTravelMondoProduct(widget === null || widget === void 0 ? void 0 : widget["elements"])) {
            titleHub = getCmsLabel(queryClient, "hub", "titleRecognizedTravelMondo") || "<b>Offerte disponibili in ".concat(nation === null || nation === void 0 ? void 0 : nation.name, ":</b>");
        } else {
            titleHub = getCmsLabel(queryClient, "hub", "titleRecognized") || "<b>Offerte disponibili in ".concat(nation === null || nation === void 0 ? void 0 : nation.name, ":</b>");
        }
    }
    return _object_spread_props(_object_spread({}, widget), {
        titleHtml: titleHub,
        titleAlternativeHtml: convertSpecialChars((widget === null || widget === void 0 ? void 0 : widget["titleAlternativeHtml"]) || ""),
        elements: (ref3 = widget === null || widget === void 0 ? void 0 : (ref2 = widget["elements"]) === null || ref2 === void 0 ? void 0 : ref2.filter(function(element) {
            var foundedFilterElement = getProductInCsv(element === null || element === void 0 ? void 0 : element.cmsId);
            var isLegacyCard = (element === null || element === void 0 ? void 0 : element.isLegacyCard) === "true";
            var isEditorialCard = (element === null || element === void 0 ? void 0 : element.isEditorialCard) === "true";
            if (isNationExtraEu(nation) && !foundedFilterElement) {
                return false;
            }
            if (isLegacy) {
                if (isSmartPassport(element) || !isNationExtraEu(nation)) return isLegacyCard;
                return isLegacyCard && foundedFilterElement;
            }
            if (!isLogged) {
                var isShowCardAnonymous = !isLegacyCard && !isSmartPassport(element);
                if (isNationExtraEu(nation)) return isShowCardAnonymous && foundedFilterElement;
                return isShowCardAnonymous;
            }
            // else return all activable products & all editorial products that have DxlId
            var isCatalogProduct = dxlCatalogIds === null || dxlCatalogIds === void 0 ? void 0 : dxlCatalogIds.includes(element.dxlId);
            var isEditorialProduct = isEditorialCard && (element === null || element === void 0 ? void 0 : element.dxlId);
            if (isEditorialProduct) return !isLegacyCard;
            return isCatalogProduct;
        })) === null || ref3 === void 0 ? void 0 : ref3.map(function(element) {
            var foundedFilterElement = getProductInCsv(element === null || element === void 0 ? void 0 : element.cmsId);
            return _object_spread_props(_object_spread({}, element, foundedFilterElement && {
                overrideCostRenewalHtml: foundedFilterElement === null || foundedFilterElement === void 0 ? void 0 : foundedFilterElement.offerPriceHtml,
                shortCharacteristics: foundedFilterElement === null || foundedFilterElement === void 0 ? void 0 : foundedFilterElement.shortCharacteristics
            }), {
                actions: getProductActions(element === null || element === void 0 ? void 0 : element.actions, element.dxlId)
            });
        })
    });
};
/**
 *
 * @param queryClient
 * @param aemData
 * @param isLogged
 * @param isEnriched
 * @param isLegacy
 * @param nation
 * @param consumerOffersCSV
 * @param activableProducts
 * @param alreadyActivatedOffers
 * @param consistency
 * @param onActiveProduct
 */ var organizeWidgets = function(queryClient, aemData, isLogged, isEnriched, isLegacy, nation, consumerOffersCSV, activableProducts, alreadyActivatedOffers, consistency, onActiveProduct) {
    var ref, ref1, ref2, ref3;
    var organizedWidgetProducts = Object.values(aemData).map(function(e) {
        if ((e === null || e === void 0 ? void 0 : e.type) === ICMSWidgetType.ANCHORS) return organizeWidgetAnchor(e, aemData, nation);
        if ((e === null || e === void 0 ? void 0 : e.type) !== ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS) return e;
        var products = organizeProducts(queryClient, e, isLogged, isEnriched, isLegacy, nation, consumerOffersCSV, activableProducts, alreadyActivatedOffers, consistency, onActiveProduct);
        productsProvider.setProducts((products === null || products === void 0 ? void 0 : products["elements"]) || []);
        return products;
    });
    if (!isLogged) return organizedWidgetProducts;
    // region ADD EXTRA TEXT widget for SMART PASSPORT OR EXTRAUE WITH NO PRODUCTS
    var hubProducts = organizedWidgetProducts === null || organizedWidgetProducts === void 0 ? void 0 : organizedWidgetProducts.find(function(el) {
        return el["type"] === ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS;
    });
    var isExtraEUNoProd = isNationExtraEu(nation) && (hubProducts === null || hubProducts === void 0 ? void 0 : (ref = hubProducts["elements"]) === null || ref === void 0 ? void 0 : ref.length) === 0;
    var extraEUText = getCmsLabel(queryClient, "hub", "titleNoProdExtraEu") || "Nel paese selezionato saranno <b>applicate le tariffe a consumo,</b> che variano in base alla tipologia di SIM che possiedi.";
    var smartPassportProduct = (ref1 = organizedWidgetProducts === null || organizedWidgetProducts === void 0 ? void 0 : organizedWidgetProducts.find(function(el) {
        return el["type"] === ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS;
    })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1["elements"]) === null || ref2 === void 0 ? void 0 : ref2.find(function(el) {
        return isSmartPassport(el);
    });
    var smartPassportText = (smartPassportProduct === null || smartPassportProduct === void 0 ? void 0 : (ref3 = smartPassportProduct.additionalContentOffer) === null || ref3 === void 0 ? void 0 : ref3.moreInfoContentHtml) || "";
    var moreInfoContentHtml = isExtraEUNoProd ? extraEUText : smartPassportText;
    moreInfoContentHtml = convertSpecialChars(moreInfoContentHtml);
    // if is isExtraEUNoProd remove anchors title
    if (isExtraEUNoProd && extraEUText) {
        organizedWidgetProducts = organizedWidgetProducts === null || organizedWidgetProducts === void 0 ? void 0 : organizedWidgetProducts.map(function(o) {
            if (o["type"] === ICMSWidgetType.ANCHORS) {
                return _object_spread_props(_object_spread({}, o), {
                    title: ""
                });
            }
            return o;
        });
    }
    if (moreInfoContentHtml) {
        organizedWidgetProducts === null || organizedWidgetProducts === void 0 ? void 0 : organizedWidgetProducts.splice(1, 0, {
            type: ICMSWidgetType.CUSTOM_TEXT,
            description: moreInfoContentHtml,
            onClickType: function(type, description, title) {
                simpleTextModalManager === null || simpleTextModalManager === void 0 ? void 0 : simpleTextModalManager.handleSimpleTextModal({
                    title: title,
                    description: description
                });
            }
        });
    }
    // endregion
    return organizedWidgetProducts;
};
var getLoadingWidgets = function(aemData) {
    var widgetsToHideForLoading = [
        ICMSWidgetType.APP_QR,
        ICMSWidgetType.SEARCHING_OFFERS_EU,
        ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS, 
    ];
    var filteredWidgets = Object.values(aemData).filter(function(e) {
        return !widgetsToHideForLoading.includes(e.type);
    });
    var topHeroIndex = ((filteredWidgets === null || filteredWidgets === void 0 ? void 0 : filteredWidgets.findIndex(function(el) {
        return el["type"] === ICMSWidgetType.TOP_HERO;
    })) || 0) + 1 || 1;
    filteredWidgets.splice(topHeroIndex, 0, {
        type: ICMSWidgetType.SKELETON_LOADER
    });
    return filteredWidgets;
};
/**
 * Get the consistency offer by cms offer (name or id)
 * @param consistency
 * @param cmsOffersLists
 * @param returnFistOfferName
 */ var getConsistencyOffer = function(consistency, cmsOffersLists, returnFistOfferName) {
    var ref, ref1, ref2;
    if (!consistency) return null;
    var basePlanOffer = consistency === null || consistency === void 0 ? void 0 : (ref = consistency.simItems) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.sections) === null || ref2 === void 0 ? void 0 : ref2.find(function(el) {
        var ref;
        return ((ref = el.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) == IGetItemType.BASE_PLAN;
    });
    if (returnFistOfferName) {
        var ref3;
        return (basePlanOffer === null || basePlanOffer === void 0 ? void 0 : (ref3 = basePlanOffer.products) === null || ref3 === void 0 ? void 0 : ref3[0]) || null;
    }
    var foundedSection = null;
    cmsOffersLists === null || cmsOffersLists === void 0 ? void 0 : cmsOffersLists.forEach(function(cOL) {
        var ref;
        var foundedPlan = basePlanOffer === null || basePlanOffer === void 0 ? void 0 : (ref = basePlanOffer.products) === null || ref === void 0 ? void 0 : ref.find(function(el) {
            var ref, ref1;
            return ((ref = el.name) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (cOL === null || cOL === void 0 ? void 0 : (ref1 = cOL.offerName) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
        });
        if (foundedPlan && !foundedSection) foundedSection = foundedPlan;
    });
    return foundedSection;
};
/**
 * Get widgets for Anonymous and logged user
 * @param aemData
 * @param isLogged
 * @param consistency
 * @param isLoading
 */ var getWidgetsForLoggedAndAnonymous = function(cmsData, isLogged, consistency, isLoading) {
    // TODO: TMP logic for appqr and SEARCHING_OFFERS_EU
    var aemData = changeMultipleWidgetType(cmsData, [
        {
            key: "appqr_",
            replaceKey: ICMSWidgetType.APP_QR_SEC
        }, 
    ]);
    if (isLoading) {
        return getLoadingWidgets(aemData);
    }
    var isApp = AppMobile.checkIsApp();
    // 06/10: For now ICMSWidgetType.SEARCHING_OFFERS_EU is always hidden we are waiting api
    var widgetsToHideForApp = [
        ICMSWidgetType.APP_QR,
        ICMSWidgetType.SEARCHING_OFFERS_EU
    ];
    var widgetsToHideForLogged = [
        ICMSWidgetType.APP_QR,
        ICMSWidgetType.SEARCHING_OFFERS_EU
    ];
    var widgetsToShowForLogged = [
        ICMSWidgetType.SEARCHING_OFFERS_EU,
        ICMSWidgetType.APP_QR_SEC
    ];
    return Object.values(aemData).filter(function(e) {
        if (isLogged) {
            // TODO remove SEARCHING_OFFERS_EU (consistency) if offer retrieved from DXL is not configured in CMS
            if (e.type === ICMSWidgetType.SEARCHING_OFFERS_EU) {
                var consistencyOffer = getConsistencyOffer(consistency, (e === null || e === void 0 ? void 0 : e.offerList) || []);
                if (!consistencyOffer) {
                    return false;
                }
            }
            return ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type] !== undefined && !widgetsToHideForLogged.includes(ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type]);
        }
        if (isApp) {
            return ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type] !== undefined && !widgetsToShowForLogged.includes(ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type]) && !widgetsToHideForApp.includes(ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type]);
        }
        return ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type] !== undefined && !widgetsToShowForLogged.includes(ICMSWidgetType[e === null || e === void 0 ? void 0 : e.type]);
    });
};
export { convertSpecialChars, getConsistencyOffer, getWidgetsForLoggedAndAnonymous, organizeWidgets };
