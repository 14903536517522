import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-height: 200px;\n  max-height: 300px;\n  border-radius: 20px 20px 0 0;\n  padding-left: 22px;\n  padding-right: 22px;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    padding-left: 36px;\n    padding-right: 36px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n    padding-left: 36px;\n    padding-right: 36px;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 70px;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 70px;\n  background-color: white;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 30px;\n  margin-bottom: 8px;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var ProductCardTopImageLoader = styled.div.withConfig({
    componentId: "sc-f0a5eb3b-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet, function(param) {
    var large = param.large;
    return large ? "300px" : "150px";
}, breakpoints.desktop, function(param) {
    var large = param.large;
    return large ? "300px" : "200px";
});
export var ProductCardTextLoader = styled.div.withConfig({
    componentId: "sc-f0a5eb3b-1"
})(_templateObject1(), colors.$ffffff);
export var ProductCardListLoaderEmpty = styled.div.withConfig({
    componentId: "sc-f0a5eb3b-2"
})(_templateObject2());
export var ProductCardListLoader = styled.div.withConfig({
    componentId: "sc-f0a5eb3b-3"
})(_templateObject3(), colors.$ffffff);
