export var evaluateCmsData = function(cmsData) {
    var brightSkyObj = cmsData === null || cmsData === void 0 ? void 0 : cmsData.brightsky;
    return {
        isVisible: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.isVisible) || "false",
        text: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.text) || "",
        redirectUrl: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.redirectUrl) || "",
        redirectLabel: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.redirectLabel) || "",
        imagePath: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.imagePath) || "",
        marketId: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.marketId) || "",
        srcScript: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.srcScript) || "",
        mode: (brightSkyObj === null || brightSkyObj === void 0 ? void 0 : brightSkyObj.mode) || "popup"
    };
};
