import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n  }\n\n  header {\n    display: none !important;\n  }\n\n  #sticky-offer-product {\n    display: none;\n  }\n\n  #sticky-offer-summary {\n    visibility: hidden;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  top: 0;\n  z-index: 4;\n  width: 100%;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
export var CoverageToolModalContainer = styled.div.withConfig({
    componentId: "sc-f843beb5-0"
})(_templateObject1());
