import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    margin: 0 auto;\n    padding: 32px 20px;\n    font-style: normal;\n    color: ",
        ";\n    width: 287px\n\n    @media (min-width: ",
        ") {\n        width: 366px;\n    }\n\n    @media (min-width: ",
        ") {\n        width: 446px;\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 400;\n    text-align: center;\n    ",
        "\n    margin-bottom: 16px;\n\n    @media (min-width: ",
        ") {\n        ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n        ",
        "\n    }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 400;\n    text-align: center;\n    ",
        "\n    margin-bottom: -16px;\n\n    @media (min-width: ",
        ") {\n        ",
        "\n        margin-bottom: 26px\n    }\n\n    @media (min-width: ",
        ") {\n        margin-bottom: 40px;\n    }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 400;\n    text-align: center;\n    ",
        "\n    margin-bottom: 20px;\n    margin-top: 32px;\n\n    @media (min-width: ",
        ") {\n        ",
        "\n    }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 8px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    display: inline-block;\n    text-align: center;\n    height: 30px;\n    width: 24px;\n    border-top: none;\n    border-left: none;\n    border-right: none;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import style from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = style.div.withConfig({
    componentId: "sc-32ee4159-0"
})(_templateObject(), colors.$262626, breakpoints.tablet, breakpoints.desktop);
export var Title = style.div.withConfig({
    componentId: "sc-32ee4159-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = style.div.withConfig({
    componentId: "sc-32ee4159-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var Detail = style.div.withConfig({
    componentId: "sc-32ee4159-3"
})(_templateObject3(), fonts.regular, pxToCssFont(12, 16), breakpoints.tablet, pxToCssFont(14, 18));
export var LinkContainer = style.div.withConfig({
    componentId: "sc-32ee4159-4"
})(_templateObject4());
export var InputContainer = style.div.withConfig({
    componentId: "sc-32ee4159-5"
})(_templateObject5());
export var OtpInput = style.input.withConfig({
    componentId: "sc-32ee4159-6"
})(_templateObject6());
