import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n  width: 100%;\n  height: auto;\n  padding: 32px 24px 32px 24px;\n  background: linear-gradient(179.94deg, rgba(255, 255, 255, 0.8) 0.05%,\n  rgba(255, 255, 255, 0.6) 23.01%, rgba(255, 255, 255, 0) 30.46%,\n  rgba(255, 255, 255, 0.1) 30.46%);\n\n  @media(min-width: ",
        "){\n    background: linear-gradient(89.69deg, rgba(255, 255, 255, 0.9) 0.28%,\n    rgba(255, 255, 255, 0) 50.01%);\n    padding: 40px 32px 40px 32px;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 80px;\n    gap: 24px;\n  };\n\n  @media(min-width: ",
        ") {\n    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%,\n    rgba(255, 255, 255, 0) 50%);\n    padding: 48px 80px 48px 80px;\n    flex-direction: row;\n    gap: 32px;\n    justify-content: space-between;\n    margin-bottom: 72px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-e3c1ab17-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
