import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: ",
        ";\n  justify-content: center;\n  align-content: center;\n  text-align: left;\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n  @media (min-width: ",
        ") {\n    gap: 24px;\n    padding: 64px 40px;\n  }\n  @media (min-width: ",
        ") {\n    gap: 24px;\n    padding: 64px 109px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 16px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        ";\n  margin-bottom: 16px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  all: unset; /* Resets all inherited and default styles */\n  display: block; /* Block display like a div */\n  cursor: pointer; /* Optional: To keep the pointer behavior */\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "/* @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  } */\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 300;\n  /* margin-bottom: 32px; */\n  ",
        " /* @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  } */\n\n   p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin: 0 auto;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, getTypography, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-82bd30f-0"
})(_templateObject(), function(param) {
    var isApp = param.isApp;
    return isApp ? "inherit" : "40px 44px";
}, breakpoints.tablet, breakpoints.desktop);
export var SecondContainer = styled.div.withConfig({
    componentId: "sc-82bd30f-1"
})(_templateObject1());
export var HeaderContainer = styled.div.withConfig({
    componentId: "sc-82bd30f-2"
})(_templateObject2());
export var SecondaryTitle = styled.div.withConfig({
    componentId: "sc-82bd30f-3"
})(_templateObject3(), fonts.regular, getTypography("h5.bold"));
export var SecondaryDescription = styled.div.withConfig({
    componentId: "sc-82bd30f-4"
})(_templateObject4(), fonts.regular, getTypography("body2.regular"), pxToCssFont(24, 32));
export var CloseIcon = styled.button.withConfig({
    componentId: "sc-82bd30f-5"
})(_templateObject5());
export var Title = styled.div.withConfig({
    componentId: "sc-82bd30f-6"
})(_templateObject6(), fonts.regular, getTypography("body2.bold"), breakpoints.tablet, breakpoints.desktop);
export var Description = styled.div.withConfig({
    componentId: "sc-82bd30f-7"
})(_templateObject7(), fonts.regular, getTypography("body1.regular"), breakpoints.tablet, breakpoints.desktop);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-82bd30f-8"
})(_templateObject8());
