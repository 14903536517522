import { stickyBannerHeaderOrganize } from "@vfit/shared/data-access";
var getBannerConfig = function(queryClient, bannerCustomConfig) {
    var ref = bannerCustomConfig || {}, customHtml = ref.htmlDesktop, customUseGlobalBanner = ref.useGlobalBanner;
    var globalBannerCms = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getStickyBannerCms");
    var globalStickyBannerConfig = stickyBannerHeaderOrganize(globalBannerCms);
    if (customHtml) {
        if (!customUseGlobalBanner) {
            return bannerCustomConfig || {};
        }
        return globalStickyBannerConfig;
    }
    return globalStickyBannerConfig;
};
export { getBannerConfig };
