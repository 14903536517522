import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n  margin-bottom: 48px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 45px;\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n\n  @media (min-width: ",
        ") {\n    // margin-bottom: 120px;\n    justify-content: space-between;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    text-align: left;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    ",
        "\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    margin-top: 12px;\n  }\n\n  p {\n    margin-top: 12px;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin-top: 0;\n      text-align: left;\n      max-width: 420px;\n    }\n\n    h3 {\n      ",
        "\n    }\n\n    h4 {\n      ",
        "\n      margin-top: 14px;\n    }\n\n    p {\n      margin-top: 14px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      text-align: left;\n      max-width: 624px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-right: 33px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0 24px;\n  }\n\n  .Image {\n    height: 64px;\n    width: 64px;\n    top: 0;\n    border-radius: 0;\n    margin: 0 auto;\n  }\n\n  .Player {\n    height: 64px;\n    width: 64px;\n    top: 0;\n    border-radius: 0;\n  }\n\n  .Title {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    letter-spacing: 0;\n    margin: 12px 24px;\n    text-align: center;\n\n    @media (min-width: ",
        ") {\n      text-align: left;\n    }\n  }\n\n  .Description {\n    div {\n      margin: 0;\n      padding-bottom: 32px;\n\n      p,\n      span {\n        font-family: ",
        ";\n        ",
        "\n        font-weight: 400 !important;\n        letter-spacing: 0;\n        text-align: center;\n      }\n    }\n\n    @media (min-width: ",
        ") {\n      div {\n        padding-bottom: 0;\n\n        p {\n          text-align: left;\n          ",
        ";\n          margin-bottom: 15px;\n        }\n      }\n    }\n\n    @media (min-width: ",
        ") {\n      div {\n        padding-bottom: 0;\n\n        p {\n          text-align: left;\n          ",
        ";\n          margin-bottom: 15px;\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ProductSolutionsContainer = styled.div.withConfig({
    componentId: "sc-b5819a1c-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop, breakpoints.bigDesktop);
export var ProductSolutionsText = styled.div.withConfig({
    componentId: "sc-b5819a1c-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(20, 26), fonts.light, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(30, 38), pxToCssFont(24, 30), breakpoints.bigDesktop);
export var ProductSolutionsProduct = styled.div.withConfig({
    componentId: "sc-b5819a1c-2"
})(_templateObject2());
export var Container = styled.div.withConfig({
    componentId: "sc-b5819a1c-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(36, 45), breakpoints.tablet, fonts.regular, pxToCssFont(24, 30), breakpoints.tablet, pxToCssFont(24, 30), breakpoints.desktop, pxToCssFont(24, 30));
