import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    max-height: inherit;\n    margin: 0 auto;\n    width: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n    -ms-overflow-style: none; /* for Internet Explorer, Edge */\n    scrollbar-width: 0;\n\n    ::-webkit-scrollbar {\n      width: 0; /* for Chrome, Safari, and Opera */\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    max-height: inherit;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  margin-bottom: 24px;\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  .maskInputFieldset {\n    margin-top: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    gap: 20px;\n  }\n\n  .textInput {\n    width: 100%;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 16px;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    column-gap: 20.36px;\n  }\n\n  @media (min-width: ",
        ") {\n    column-gap: 32px;\n  }\n\n  .nation {\n    label {\n      top: ",
        ";\n      ",
        "\n      z-index: 2;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    width: 230.64px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 296px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  margin-top: 16px;\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 20px;\n    margin-bottom: 45px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  z-index: 2;\n\n  @media (max-width: ",
        ") {\n    position: relative;\n    background: ",
        ";\n  }\n\n  .buttonSlide {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 255px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 295px;\n    }\n  }\n\n  button:disabled {\n    background: white;\n    border-color: ",
        ";\n\n    span {\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-45472f6-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-45472f6-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.desktop, pxToCssFont(36, 45));
export var Text = styled.div.withConfig({
    componentId: "sc-45472f6-2"
})(_templateObject2(), fonts.regular, colors.$262626, pxToCssFont(18, 24), breakpoints.tablet, breakpoints.desktop);
export var Div = styled.div.withConfig({
    componentId: "sc-45472f6-3"
})(_templateObject3(), breakpoints.tablet);
export var InputContainer = styled.div.withConfig({
    componentId: "sc-45472f6-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop, pxToRem(-13), pxToCssFont(14, 18));
export var BirthPlaceContainer = styled.div.withConfig({
    componentId: "sc-45472f6-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var Footer = styled.div.withConfig({
    componentId: "sc-45472f6-6"
})(_templateObject6(), fonts.regular, pxToCssFont(14, 18), breakpoints.tablet);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-45472f6-7"
})(_templateObject7(), breakpoints.tablet, colors.$ffffff, breakpoints.tablet, breakpoints.desktop, colors.$bebebe, colors.$bebebe);
