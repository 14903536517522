export var organizeEditField = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21;
    return {
        titleNumber: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.editNumber) === null || ref2 === void 0 ? void 0 : ref2.title) || "",
        titleEmail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfo) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.clientLogged) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.newEmail) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
        subtitleNumber: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientLogged) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.newNumber) === null || ref8 === void 0 ? void 0 : ref8.description) || "",
        subtitleEmail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfo) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.clientLogged) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.newEmail) === null || ref11 === void 0 ? void 0 : ref11.subtitle) || "",
        buttonLableNumber: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref12 = dataFromCms.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.clientLogged) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.newNumber) === null || ref14 === void 0 ? void 0 : ref14.buttonLabel) || "",
        buttonLableEmail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref15 = dataFromCms.personalinfo) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.clientLogged) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.newEmail) === null || ref17 === void 0 ? void 0 : ref17.buttonLabel) || "",
        lableNumber: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref18 = dataFromCms.personalinfo) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.selectInput) === null || ref19 === void 0 ? void 0 : ref19.telephone) || "",
        lableEmail: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref20 = dataFromCms.personalinfo) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.selectInput) === null || ref21 === void 0 ? void 0 : ref21.email) || ""
    };
};
