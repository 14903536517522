import { purify } from "@vfit/shared/themes";
export var organizeProductCharacteristicsTable = function(cmsData) {
    var ref;
    return {
        title: (cmsData === null || cmsData === void 0 ? void 0 : cmsData.title) || "",
        description: ((ref = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : cmsData.description) || ""
        ])) === null || ref === void 0 ? void 0 : ref[0]) || "",
        table: {
            btn: {
                buttonLabel: (cmsData === null || cmsData === void 0 ? void 0 : cmsData.buttonLabel) || "",
                buttonLink: (cmsData === null || cmsData === void 0 ? void 0 : cmsData.buttonLink) || ""
            },
            table: cmsData === null || cmsData === void 0 ? void 0 : cmsData.table.map(function(obj) {
                var ref;
                return {
                    title: (obj === null || obj === void 0 ? void 0 : obj.title) || "",
                    value: ((ref = purify([
                        (obj === null || obj === void 0 ? void 0 : obj.value) || ""
                    ])) === null || ref === void 0 ? void 0 : ref[0]) || ""
                };
            })
        }
    };
};
