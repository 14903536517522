import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import crypto from "crypto-es";
import { LOGGED_USER_KEYS } from "@vfit/consumer/data-access";
import { handleEnv } from "@vfit/shared/providers";
import { checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
var getEnrgInfo = function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37;
    var SHA256 = crypto.SHA256;
    var user = getFromLocalStorageByKey("user");
    var appSessionFromStorage = checkWindow() && localStorage.getItem("appSession");
    var silentLogin = getFromLocalStorageByKey("silentLogin");
    var identifyEnrichement = getFromLocalStorageByKey("identify");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var customerData = getFromLocalStorageByKey("customerData");
    var appSession = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
    var appMsisdn = appSession === null || appSession === void 0 ? void 0 : appSession.value;
    var visitorLoginStatus = appMsisdn || isLoggedUser ? "logged in" : "logged out";
    return _object_spread({
        visitor_login_type: "web"
    }, (user === null || user === void 0 ? void 0 : (ref = user.customer) === null || ref === void 0 ? void 0 : ref[0].id) && {
        visitor_customer_id: user === null || user === void 0 ? void 0 : user.customer[0].id
    }, (user === null || user === void 0 ? void 0 : (ref1 = user.taggedInfoCust) === null || ref1 === void 0 ? void 0 : ref1.email) && {
        visitor_id_email: SHA256(user === null || user === void 0 ? void 0 : (ref2 = user.taggedInfoCust) === null || ref2 === void 0 ? void 0 : ref2.email).toString()
    }, (user === null || user === void 0 ? void 0 : (ref3 = user.customer) === null || ref3 === void 0 ? void 0 : ref3[0].msisdns) && {
        visitor_id_asset_list: (ref5 = user === null || user === void 0 ? void 0 : (ref4 = user.customer) === null || ref4 === void 0 ? void 0 : ref4[0].msisdns) === null || ref5 === void 0 ? void 0 : ref5.map(function(msisdn) {
            var ref;
            return ((ref = SHA256(msisdn)) === null || ref === void 0 ? void 0 : ref.toString()) || "";
        })
    }, identifyEnrichement && identifyEnrichement.id && {
        visitor_id_asset_active: SHA256(identifyEnrichement.id).toString()
    }, appMsisdn && {
        visitor_id_asset_active: SHA256(appMsisdn).toString()
    }, visitorLoginStatus && {
        visitor_login_status: visitorLoginStatus
    }, customerData && ((ref6 = customerData[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.owningIndividual) === null || ref7 === void 0 ? void 0 : ref7.birthDate) && {
        visitor_customer_dob: (ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.birthDate
    }, customerData && ((ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : ref11.gender) && {
        visitor_customer_gender: (ref12 = customerData[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.owningIndividual) === null || ref13 === void 0 ? void 0 : ref13.gender
    }, customerData && ((ref14 = customerData[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.billingAddress) === null || ref15 === void 0 ? void 0 : ref15.city) && {
        visitor_customer_location_city: (ref16 = customerData[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.billingAddress) === null || ref17 === void 0 ? void 0 : ref17.city
    }, customerData && ((ref18 = customerData[0]) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.owningIndividual) === null || ref19 === void 0 ? void 0 : ref19.nation) && {
        visitor_customer_location_country: (ref20 = customerData[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.owningIndividual) === null || ref21 === void 0 ? void 0 : ref21.nation
    }, customerData && ((ref22 = customerData[0]) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.billingAddress) === null || ref23 === void 0 ? void 0 : ref23.postalCode) && {
        visitor_customer_location_id: (ref24 = customerData[0]) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.billingAddress) === null || ref25 === void 0 ? void 0 : ref25.postalCode
    }, customerData && ((ref26 = customerData[0]) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.owningIndividual) === null || ref27 === void 0 ? void 0 : ref27.firstName) && {
        visitor_customer_name_first: (ref28 = customerData[0]) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.owningIndividual) === null || ref29 === void 0 ? void 0 : ref29.firstName
    }, customerData && ((ref30 = customerData[0]) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.owningIndividual) === null || ref31 === void 0 ? void 0 : ref31.lastName) && {
        visitor_customer_name_last: (ref32 = customerData[0]) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.owningIndividual) === null || ref33 === void 0 ? void 0 : ref33.lastName
    }, customerData && ((ref34 = customerData[0]) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.billingAddress) === null || ref35 === void 0 ? void 0 : ref35.stateOrProvince) && {
        visitor_customer_region: (ref36 = customerData[0]) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.billingAddress) === null || ref37 === void 0 ? void 0 : ref37.stateOrProvince
    });
};
/**
 * Method to organize the enrgPage
 * @param queryClient
 */ var organizeEnrgPage = function(queryClient) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    var cmsPage = queryClient.getQueryData("getEnrgPage");
    var cmsPageNavigation = queryClient.getQueryData("getPageNavigation");
    var ref12 = cmsPage || {}, pagesparams = ref12.pagesparams, widgets = ref12.widgets, tagging = ref12.tagging, seo = ref12.seo, topheaderbanner = ref12.topheaderbanner, fullbanner = ref12.fullbanner;
    return {
        params: {
            key: pagesparams === null || pagesparams === void 0 ? void 0 : pagesparams.key,
            layout: "",
            haveHeader: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref = pagesparams.visibility) === null || ref === void 0 ? void 0 : ref.haveHeader) === "true",
            isHeaderSmall: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref1 = pagesparams.visibility) === null || ref1 === void 0 ? void 0 : ref1.isHeaderSmall) === "true",
            isNewHeader: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref2 = pagesparams.visibility) === null || ref2 === void 0 ? void 0 : ref2.isNewHeader) === "true",
            haveFooter: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref3 = pagesparams.visibility) === null || ref3 === void 0 ? void 0 : ref3.haveFooter) === "true",
            isShow: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref4 = pagesparams.visibility) === null || ref4 === void 0 ? void 0 : ref4.showPages) === "true",
            isDisabledInitialTrack: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref5 = pagesparams.visibility) === null || ref5 === void 0 ? void 0 : ref5.isDisabledInitialTrack) === "true",
            isProtected: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref6 = pagesparams.visibility) === null || ref6 === void 0 ? void 0 : ref6.isProtected) === "true",
            protectedPass: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref7 = pagesparams.visibility) === null || ref7 === void 0 ? void 0 : ref7.protectedPass) || "",
            path: (pagesparams === null || pagesparams === void 0 ? void 0 : (ref8 = pagesparams.urlBuilder) === null || ref8 === void 0 ? void 0 : ref8.map(function(urlBuilder) {
                var ref, ref1;
                return ((ref1 = (ref = urlBuilder.page) === null || ref === void 0 ? void 0 : ref.trim()) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) || "";
            })) || [
                ""
            ],
            elements: _object_spread({
                pageNavigation: cmsPageNavigation
            }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref9 = pagesparams.apiPath) === null || ref9 === void 0 ? void 0 : ref9.apiProductsList) && {
                apiProductsList: pagesparams.apiPath.apiProductsList
            }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref10 = pagesparams.apiPath) === null || ref10 === void 0 ? void 0 : ref10.apiOfferList) && {
                apiOfferList: pagesparams.apiPath.apiOfferList
            }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref11 = pagesparams.apiPath) === null || ref11 === void 0 ? void 0 : ref11.apiDeviceList) && {
                apiDeviceList: pagesparams.apiPath.apiDeviceList
            }, widgets && (widgets === null || widgets === void 0 ? void 0 : widgets.sliders) && {
                widgets: widgets
            }, topheaderbanner && {
                topheaderbanner: topheaderbanner
            }, fullbanner && {
                fullbanner: fullbanner
            }, seo && {
                seo: seo
            }, tagging && {
                taggingOptions: {
                    event_category: (tagging === null || tagging === void 0 ? void 0 : tagging.eventCategory) || "",
                    page_section: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSection) || "",
                    page_subsection: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSubsection) || "",
                    page_type: (tagging === null || tagging === void 0 ? void 0 : tagging.pageType) || "",
                    event: (tagging === null || tagging === void 0 ? void 0 : tagging.event) || "",
                    eventLabel: (tagging === null || tagging === void 0 ? void 0 : tagging.eventLabel) || "",
                    journey_type: (tagging === null || tagging === void 0 ? void 0 : tagging.journeyType) || "",
                    product_target_segment: (tagging === null || tagging === void 0 ? void 0 : tagging.productTargetSegment) || "",
                    product_category: (tagging === null || tagging === void 0 ? void 0 : tagging.productCategory) || ""
                }
            })
        }
    };
};
/**
 * NOT DELETE
 * IS Temporary FOR ENRG FLOW in production/test
 */ var getFwvPostEP = function() {
    var ref;
    var isDevLocal = handleEnv("NODE_ENV") === "development";
    if (isDevLocal) return "http://localhost:6767/api";
    var env = (process === null || process === void 0 ? void 0 : (ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_FW_POST_FORM"]) || "";
    return atob(env) || "";
};
export { getEnrgInfo, organizeEnrgPage, getFwvPostEP };
