export var IFallbackActionType;
(function(IFallbackActionType) {
    IFallbackActionType["FALLBACK_ACCEPT"] = "1";
    IFallbackActionType["FALLBACK_CONTINUE"] = "2";
    IFallbackActionType["FALLBACK_CTC"] = "3";
    IFallbackActionType["FALLBACK_REDIRECT"] = "4";
})(IFallbackActionType || (IFallbackActionType = {}));
export var ETrackConvergenceModal;
(function(ETrackConvergenceModal) {
    ETrackConvergenceModal["OFFER_STEP"] = "offerStep";
    ETrackConvergenceModal["DETAILS_STEP"] = "detailsStep";
})(ETrackConvergenceModal || (ETrackConvergenceModal = {}));
