import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 32px 20px;\n\n  @media (min-width: ",
        ") {\n    margin: 64px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 64px 55px;\n  }\n\n  .button-slide-container {\n    @media (min-width: ",
        ") {\n      position: absolute;\n      bottom: 64px;\n      left: 40px;\n      right: 40px;\n    }\n\n    @media (min-width: ",
        ") {\n      left: 55px;\n      right: 55px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var ButtonWrapper = styled.div.withConfig({
    componentId: "sc-ee82f65b-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
